import React, {useEffect, useState} from 'react';
import '../styles/Tutorials.css';
import DashBoardNavBar from "./DashboardNavbar";
import {useNavigate} from "react-router-dom";
import firebase from "../firebaseConfig";

const Tutorials = ({user}) => {
    const [userName, setUserName] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        // Vérifier si l'utilisateur dispose d'un abonnement valide
        const checkPlanValidity = async () => {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    const data = docSnapshot.data();
                    setUserName(data.username)
                }
            }else {
                // Utilisateur non connecté, gérer le cas approprié ici si nécessaire
                // Par exemple, rediriger vers la page de connexion
                navigate('/login');
            }
        };
        checkPlanValidity();
    }, []);
    return (
        <>
            <DashBoardNavBar userName={userName} boldItem={"tutorials"} />
            <div className="container">
                <h1 className="text-center mt-5 mb-3">Tutorials</h1>
                <div id="accordion">
                    {/* Chapitre 1 */}
                    <div className="card">
                        <div className="card-header" id="headingOne">
                            <h5 className="mb-0">
                                <button
                                    className="btn btn-link collapsed"
                                    data-toggle="collapse"
                                    data-target="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                >
                                    Chapitre 1: Introduction
                                </button>
                            </h5>
                        </div>

                        <div
                            id="collapseOne"
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#accordion"
                        >
                            <div className="card-body">
                                <p>
                                    Bienvenue dans le chapitre d'introduction. Dans cette vidéo,
                                    nous allons vous donner un aperçu du sujet que nous allons
                                    aborder dans ce tutoriel.
                                </p>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        className="embed-responsive-item"
                                        src="https://www.youtube.com/embed/VIDEO_ID"
                                        allowFullScreen
                                        title="Chapitre 1: Introduction"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Chapitre 2 */}
                    <div className="card">
                        <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                                <button
                                    className="btn btn-link collapsed"
                                    data-toggle="collapse"
                                    data-target="#collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="collapseTwo"
                                >
                                    Chapitre 2: Les bases
                                </button>
                            </h5>
                        </div>
                        <div
                            id="collapseTwo"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#accordion"
                        >
                            <div className="card-body">
                                <p>
                                    Dans ce chapitre, nous allons couvrir les bases du sujet, y
                                    compris les termes et concepts importants que vous devez
                                    comprendre.
                                </p>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        className="embed-responsive-item"
                                        src="https://www.youtube.com/embed/VIDEO_ID"
                                        allowFullScreen
                                        title="Chapitre 2: Les bases"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Chapitre 3 */}
                    <div className="card">
                        <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                                <button
                                    className="btn btn-link collapsed"
                                    data-toggle="collapse"
                                    data-target="#collapseThree"
                                    aria-expanded="false"
                                    aria-controls="collapseThree"
                                >
                                    Chapitre 3: Avancé
                                </button>
                            </h5>
                        </div>
                        <div
                            id="collapseThree"
                            className="collapse"
                            aria-labelledby="headingThree"
                            data-parent="#accordion"
                        >
                            <div className="card-body">
                                <p>
                                    Dans ce chapitre avancé, nous allons plonger plus profondément
                                    dans le sujet et explorer des techniques plus avancées.
                                </p>
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe
                                        className="embed-responsive-item"
                                        src="https://www.youtube.com/embed/VIDEO_ID"
                                        allowFullScreen
                                        title="Chapitre 3: Avancé"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Tutorials;
