import React, {useEffect, useState} from 'react';
import { UncontrolledBoard } from '@caldwell619/react-kanban';
import '@caldwell619/react-kanban/dist/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Card} from "antd";
import SingleTaskingEditModal from "./Dashboard_Tabs/SingleTaskingEditModal";

const KanbanBoard = ({ title, categoryName, onHandleEditList, data }) => {
    const [boardData, setBoardData] = useState(data);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);

    const onCardDragEnd = (board) => {
        setBoardData(board)
        onHandleEditList(categoryName, board);
    };
    const handleCardClick = (card) => {
        // Gérez ce qui se passe lorsque l'utilisateur clique sur une carte
        setSelectedCard(card)
        setEditModalOpen(true)
    };
    const handleSaveTask = ({ title, description }) => {
        if (selectedCard) {
            // Créez une copie des données actuelles
            const updatedData = { ...boardData };

            // Parcourez chaque colonne pour trouver la carte à mettre à jour
            updatedData.columns.forEach((column) => {
                const cardIndex = column.cards.findIndex((card) => card.id === selectedCard.id);

                if (cardIndex !== -1) {
                    // Remplacez la carte par la version mise à jour
                    column.cards[cardIndex] = {
                        ...column.cards[cardIndex],
                        title: title,
                        description: description,
                    };

                    // Mise à jour des données de la carte
                    setBoardData(updatedData);

                    // Appeler la fonction de rappel pour mettre à jour les données dans Firebase
                    onHandleEditList(categoryName, updatedData);
                }
            });
        }

        // Fermez la popup d'édition
        handleCloseModal();
    };

    const handleDeleteTask = () => {
        if (selectedCard) {
            // Créez une copie des données actuelles
            const updatedData = { ...boardData };

            // Parcourez chaque colonne pour trouver et supprimer la carte
            updatedData.columns.forEach((column) => {
                const cardIndex = column.cards.findIndex((card) => card.id === selectedCard.id);

                if (cardIndex !== -1) {
                    // Supprimez la carte de la colonne
                    column.cards.splice(cardIndex, 1);

                    // Mise à jour des données de la carte
                    setBoardData(updatedData);

                    // Appeler la fonction de rappel pour mettre à jour les données dans Firebase
                    onHandleEditList(categoryName, updatedData);
                }
            });
        }

        // Fermez la popup d'édition
        handleCloseModal();
    };


    const handleCloseModal =() => {
        setEditModalOpen(false)
    }

    const CustomCard = ({ card, onClick }) => {
        // Styles personnalisés pour les cartes
        const cardStyles = {
            padding: '5px', // Espacement intérieur
            marginBottom: '8px', // Marge inférieure pour espacement entre les cartes
            background : 'rgba(255,255,170,0.12)',
            border:'none',
            cursor: 'pointer', // Curseur de type main pour indiquer la cliquabilité
            maxWidth: '300px', // Largeur maximale pour éviter le débordement
            whiteSpace: 'normal', // Permet au texte de se répartir sur plusieurs lignes
        };
        const maxLength = 115;
        const truncatedDescription =
            card.description.length > maxLength
                ? card.description.slice(0, maxLength) + '...'
                : card.description;

        return (
            <div onClick={() => onClick(card)}>
                <div> {/* Appliquez le style en ligne ici */}
                    <Card card={card} style={cardStyles}>
                        {/* Affichez le titre et la description de la carte */}
                        <div style={{ wordBreak:"break-word"}} >
                            <div style={{width:"100% !important"}}>
                                <h6>{card.title}</h6>
                                <p>{truncatedDescription}</p>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        );
    };
    return (
        <div className="container mt-4">
            { editModalOpen === true ?
                <SingleTaskingEditModal onSave={handleSaveTask} onDelete={handleDeleteTask} closeModal={handleCloseModal} card={selectedCard}/> :''
            }
            <div className="row">
                <h5>{title}</h5>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <UncontrolledBoard
                        initialBoard={data}
                        allowAddCard={true}
                        allowRemoveColumn={false}
                        disableColumnDrag={true}
                        allowRenameColumn={false}
                        // Utilisez votre composant de carte personnalisé avec un gestionnaire onClick
                        renderCard={(card) => (
                            <CustomCard card={card} onClick={handleCardClick}  />
                        )}
                        onCardDragEnd={onCardDragEnd}
                    />
                </div>
            </div>
        </div>
    );
};

export default KanbanBoard;