import React, {useEffect, useState} from 'react';

const ModalCausesSymptoms = ({ modalTitle, closeModal, causeValue, symptomValue, preventionValue, saveFields }) => {
    const [newCauseValue, setNewCauseValue] = useState(causeValue);
    const [newSymptomValue, setNewSymptomValue] = useState(symptomValue);
    const [newPreventionValue, setNewPreventionValue] = useState(preventionValue);
    const [isFormValid, setIsFormValid] = useState(false);

    const handleInputChange = (event, type) => {
        const inputValue = event.target.value;
        if (type === "cause") {
            setNewCauseValue(inputValue);
        } else if (type === "symptom") {
            setNewSymptomValue(inputValue);
        } else if (type === "prevention") {
            setNewPreventionValue(inputValue);
        }
    };

    useEffect(() => {
        setIsFormValid(newCauseValue?.trim() !== "" && newSymptomValue?.trim() !== "" && newPreventionValue?.trim() !== "");
    }, [newCauseValue, newSymptomValue, newPreventionValue]);

    return (
        <div className="modal fade show modal-overlay" style={{ display: "block" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{modalTitle}</h5>
                        <button className="btn-close" type="button" aria-label="Close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label htmlFor="cause">Cause:</label>
                            <textarea
                                className="form-control w-100"
                                style={{ resize: "none", minHeight: "5em" }}
                                value={newCauseValue}
                                onChange={(event) => handleInputChange(event, "cause")}
                                rows={Math.max(3, newCauseValue?.split("\n").length)}
                            ></textarea>
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="symptom">Symptom:</label>
                            <textarea
                                className="form-control w-100"
                                style={{ resize: "none", minHeight: "10em" }}
                                value={newSymptomValue}
                                onChange={(event) => handleInputChange(event, "symptom")}
                                rows={Math.max(3, newSymptomValue?.split("\n").length)}
                            ></textarea>
                        </div>
                        <div className="form-group mt-3">
                            <label htmlFor="prevention">Prevention:</label>
                            <textarea
                                className="form-control w-100"
                                style={{ resize: "none", minHeight: "10em" }}
                                value={newPreventionValue}
                                onChange={(event) => handleInputChange(event, "prevention")}
                                rows={Math.max(3, newPreventionValue?.split("\n").length)}
                            ></textarea>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-light" type="button" onClick={closeModal}>Close</button>
                            <button className="btn btn-primary" type="button" onClick={() => saveFields({newCauseValue, newSymptomValue, newPreventionValue})} disabled={!isFormValid}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalCausesSymptoms;
