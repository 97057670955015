import "../../styles/generalTabs.css";
import { DayPilot, DayPilotCalendar, DayPilotNavigator } from "@daypilot/daypilot-lite-react";
import React, { useEffect, useRef, useState } from "react";
import "../../styles/calendar.css";
import firebase from "../../firebaseConfig";
import {addDays, format} from 'date-fns';
import {AutoComplete} from "primereact/autocomplete";
import {InputText} from "primereact/inputtext";

const styles = {
    left: {
        marginRight: "10px"
    },
    main: {
        flexGrow: "1"
    }

};

const TimeManagement = ({ user }) => {
    const calendarRef = useRef();
    const colors = {
        "Workflow/Productivity": "#3c78d8",
        "Creativity/Arts": "#6aa84f",
        "Fitness/Sports": "#e1b93e",
        "Socializing/Communication/Public Speech": "#f13b3b",
        "Education/Learning": "#cd61e5",
        "Reading": "#56d5ea",
        null: "#aaaaaa"
    };
    const types = [
        { name: "Workflow/Productivity", id: "Workflow/Productivity" },
        { name: "Creativity/Arts", id: "Creativity/Arts" },
        { name: "Fitness/Sports", id: "Fitness/Sports" },
        { name: "Socializing/Communication/Public Speech", id: "Socializing/Communication/Public Speech" },
        { name: "Education/Learning", id: "Education/Learning" },
        { name: "Reading", id: "Reading" }
    ];
    const [goals, setGoals] = useState([]);
    const [hasInit, setHasInit] = useState(false);
    const [taskToDuplicateOrDelete, settaskToDuplicateOrDelete] = useState(null);
    const [showDescription, setShowDescription] = useState(false);


    useEffect(() => {
        const fetchInputValue = async () => {
            try {
                if (user) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data().TimeManagement_9;
                        const isShowDesc = docSnapshot.data().showDescription;
                        setShowDescription(isShowDesc)
                        const adjustedGoals = data.map(goal => ({
                            ...goal,
                            start: goal.start,
                            end: goal.end,
                        }));
                        const startDate = new Date();
                        setGoals(adjustedGoals);
                        const events = adjustedGoals?.map(goal => {
                            return {
                                ...goal,
                                backColor: colors[goal.type] || "",
                            };
                        });
                        calendarRef.current.control.update({ startDate, events });
                        setHasInit(true)
                    }
                }
            } catch (error) {
                console.log(error);
                // Gérer les erreurs de récupération des données
            }
        };
        fetchInputValue();
    }, [user]);

    useEffect(() => {
        const saveGoals = async () => {
            try {
                if (user && goals.length > 0) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const updatedGoals = goals.map(goal => ({
                        ...goal,
                        start: format(new Date(goal.start), "yyyy-MM-dd'T'HH:mm:ss"),
                        end: format(new Date(goal.end), "yyyy-MM-dd'T'HH:mm:ss")
                    }));
                    await docRef.update({ TimeManagement_9: updatedGoals });
                }
            } catch (error) {
                console.log(error);
                // Gérer les erreurs d'enregistrement
            }
        };
        saveGoals();
    }, [goals]);

    useEffect(() => {

        const events = goals?.map(goal => {
            return {
                ...goal,
                backColor: colors[goal.type] || ""
            };
        });
        calendarRef.current.control.update({ events });
    }, [goals]);

    const form = [
        { name: "Task / Activity", id: "text" },
        { name: "Action list / Notes", id: "description", type: "textarea" },
        { name: "Category", id: "type", type: "select", options: types },
    ];

    const duplicateTask = async (args) => {
        const { data } = args;
        const { duplicateOption, numberOfWeeks } = data;

        let start = new Date(taskToDuplicateOrDelete.e.data.start);
        let end = new Date(taskToDuplicateOrDelete.e.data.end);

        if (duplicateOption === "nextWeek") { // NEXT WEEK
            const duplicatedEvents = [];
            for (let i = 0; i < numberOfWeeks; i++) {

                start = addDays(start, 7); // Ajoute le nombre de semaines (7 jours par semaine)
                end = addDays(end, 7);

                const duplicatedEvent = {
                    id: Math.floor(Math.random() * 1000000000),
                    description: taskToDuplicateOrDelete.e.data.description,
                    start: format(start, "yyyy-MM-dd'T'HH:mm:ss"),
                    end: format(end, "yyyy-MM-dd'T'HH:mm:ss"),
                    text: taskToDuplicateOrDelete.e.data.text,
                    type: taskToDuplicateOrDelete.e.data.type,
                };

                duplicatedEvents.push(duplicatedEvent);
            }
            settaskToDuplicateOrDelete(null);
            setGoals(prevGoals => [...prevGoals, ...duplicatedEvents]);
        }else{ // TODAY
            const duplicatedEvent = {
                id: Math.floor(Math.random() * 1000000000),
                description: taskToDuplicateOrDelete.e.data.description,
                start: format(start, "yyyy-MM-dd'T'HH:mm:ss"),
                end: format(end, "yyyy-MM-dd'T'HH:mm:ss"),
                text: taskToDuplicateOrDelete.e.data.text,
                type: taskToDuplicateOrDelete.e.data.type,
            };
            settaskToDuplicateOrDelete(null)
            setGoals(prevGoals => [...prevGoals, duplicatedEvent]);
        }
    }

    const DuplicationModal = (args) => {
        const [showNumberOfWeeks, setShowNumberOfWeeks] = useState(false);
        const [duplicateOption, setDuplicateOption] = useState("today");
        const [numberOfWeeks, setNumberOfWeeks] = useState(1);

        const handleRadioChange = (e) => {
            setDuplicateOption(e.target.value);
            setShowNumberOfWeeks(e.target.value === 'nextWeek');
        };
        const handleEventDeleted= () => {
                try {
                    const deletedEventId = taskToDuplicateOrDelete.e.data.id;
                    setGoals(prevGoals => {
                        const updatedGoals = prevGoals.filter(goal => goal.id !== deletedEventId);
                        return updatedGoals;
                    });
                    // Effectuer toute autre action nécessaire pour supprimer l'événement
                } catch (error) {
                    console.log(error);
                    // Gérer les erreurs de suppression de l'événement
                }
                settaskToDuplicateOrDelete(null)
        }
        return (
            <div className="modal fade show modal-overlay" style={{ display: "block" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            ACTION
                            <button
                                className="btn-close"
                                style={{ cursor: "pointer" }}
                                type="button"
                                aria-label="Close"
                                onClick={() => settaskToDuplicateOrDelete(null)}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <div>
                                    <div class="btn-group" role="group" aria-label="Duplicate Options">
                                        <input
                                            type="radio"
                                            id="today"
                                            name="duplicateOption"
                                            value="today"
                                            className="btn-check"
                                            autoComplete="off"
                                            checked={duplicateOption === 'today'}
                                            onChange={handleRadioChange}
                                        />
                                        <label htmlFor="today" className="btn btn-outline-primary">
                                            Duplicate
                                        </label>

                                        <input
                                            type="radio"
                                            id="nextWeek"
                                            name="duplicateOption"
                                            value="nextWeek"
                                            className="btn-check"
                                            autoComplete="off"
                                            checked={duplicateOption === 'nextWeek'}
                                            onChange={handleRadioChange}
                                        />
                                        <label htmlFor="nextWeek" className="btn btn-outline-primary">
                                            Duplicate Next Weeks
                                        </label>
                                        <input
                                            type="button"
                                            id="nextWeek"
                                            name="duplicateOption"
                                            value="Delete"
                                            className="btn btn-danger"
                                            autoComplete="off"
                                            onClick={handleEventDeleted}
                                        />
                                    </div>
                                    {showNumberOfWeeks && (
                                        <div className={"m-3"}>
                                            <label>
                                                Number of weeks
                                                <input
                                                    className={"m-2"}
                                                    type="number"
                                                    name="numberOfWeeks"
                                                    required={true}
                                                    value={numberOfWeeks}
                                                    max={10}
                                                    onChange={(e) => setNumberOfWeeks(e.target.value)}
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>


                                <div className="d-flex justify-content-end mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={() => settaskToDuplicateOrDelete(null)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => {
                                            duplicateTask({
                                                data: {
                                                    duplicateOption,
                                                    numberOfWeeks: duplicateOption === "nextWeek" ? numberOfWeeks>10 ? 10 : numberOfWeeks : undefined
                                                }
                                            });
                                        }}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );


    };
    const [calendarConfig, setCalendarConfig] = useState({
        viewType: "Week",
        durationBarVisible: false,
        BusinessBeginsHour: 4,
        headerDateFormat: "dddd MM/dd/yyyy",
        timeRangeSelectedHandling: "Enabled",
        onBeforeEventRender: args => {
            args.data.areas = [
                {
                    top: 0,
                    left: 0,
                    right: 0,
                    text: args.data.text,
                    backColor: args.data.borderColor,
                    fontColor: "#ffffff",
                    padding: 4,
                    horizontalAlignment: "center"
                }
            ];
            if (showDescription===true) {
                const titleLength = args.data.text ? args.data.text.length : 0;
                let verticalPosition;
                switch (true) {
                    case titleLength > 120:
                        verticalPosition = 120;
                        break;
                    case titleLength > 100:
                        verticalPosition = 98;
                        break;
                    case titleLength > 75:
                        verticalPosition = 80;
                        break;
                    case titleLength > 50:
                        verticalPosition = 60;
                        break;
                    case titleLength > 20:
                        verticalPosition = 40;
                        break;
                    default:
                        verticalPosition = 20;
                }
                args.data.areas.push({
                    top: verticalPosition,
                    left: 0,
                    right: 0,
                    text: args.data.description,
                    padding: 4,
                });
            }

            args.data.text = null;
        },
        onTimeRangeSelected: async args => {
            const dp = calendarRef.current.control;
            const modal = await DayPilot.Modal.form(form);
            dp.clearSelection();
            if (!modal.result) {
                return;
            }
            setGoals(prevGoals => {
                const newGoal = {
                    id: DayPilot.guid(),
                    description: modal.result.description,
                    start: args.start,
                    end: args.end,
                    text: modal.result.text,
                    type: modal.result.type
                };
                return [...prevGoals, newGoal];
            });
        },
        onEventClick: async args => {
            const modal = await DayPilot.Modal.form(form, args.e.data);
            if (!modal.result) {
                return;
            }
            setGoals(prevGoals => {
                const updatedGoals = prevGoals.map(goal => {
                    if (goal.id === args.e.id()) {
                        return {
                            ...goal,
                            text: modal.result.text,
                            description: modal.result.description,
                            type: modal.result.type
                        };
                    }
                    return goal;
                });
                return updatedGoals;
            });
        },
        onEventRightClicked: async function(args) {
            settaskToDuplicateOrDelete(args)
        },
        onEventMoved: async args => {
            const e = args.e;
            const newStart = args.newStart;
            const newEnd = args.newEnd;
            setGoals(prevGoals => {
                const updatedGoals = prevGoals.map(goal => {
                    if (goal.id === e.id()) {
                        return {
                            ...goal,
                            start: newStart,
                            end: newEnd
                        };
                    }
                    return goal;
                });
                return updatedGoals;
            });
            calendarRef.current.control.update({ newStart });
        },
        onEventResized: async args => {
            const e = args.e;
            const newStart = args.newStart;
            const newEnd = args.newEnd;
            setGoals(prevGoals => {
                const updatedGoals = prevGoals.map(goal => {
                    if (goal.id === e.id()) {
                        return {
                            ...goal,
                            start: newStart,
                            end: newEnd
                        };
                    }
                    return goal;
                });
                return updatedGoals;
            });
        }
    });
    const changeShowDescription = async (args) => {
        try {
            const _showDescription = args.target.checked;
            if (user) {
                const userId = firebase.auth().currentUser.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                await docRef.update({ showDescription: _showDescription });
                setShowDescription(_showDescription);
            }
        } catch (error) {
            console.error(error);
            // Gérer les erreurs d'enregistrement
        }
    };


    return (
        <>
            <h5 className={"mt-3"}>
                <span className="introText">
                     "The bad news is time flies. The good news is you're the pilot." - Michael Altshuler
                </span>
            </h5>
            <div className={"mt-4"}>
                <div style={styles.wrap}  className={"containerCalendar"}>
                    <div style={styles.left}>
                        <DayPilotNavigator
                            selectMode={"Week"}
                            onTimeRangeSelected={args => {
                                calendarRef.current.control.update({
                                    startDate: args.day
                                });
                            }}
                        />
                        <div  className={"mt-2"}><i>Tip: Press right click on an<br/>event to duplicate or delete it !</i></div>

                        {/*
                            <div className="mt-4">
                                <input
                                    className="form-check-input m-1"
                                    type="checkbox"
                                    checked={showDescription}
                                    id="flexCheckChecked"
                                    onChange={changeShowDescription}
                                />
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                    Show Description on cards
                                </label>
                            </div>
                            */}
                        </div>
                    <div className="custom-calendar-container" style={styles.main}>
                        <DayPilotCalendar
                            {...calendarConfig}
                            ref={calendarRef}
                            weekStarts={1} // 1 pour lundi

                        />
                    </div>
                </div>
            </div>

            {taskToDuplicateOrDelete && <DuplicationModal />}

        </>
    );
};

export default TimeManagement;
