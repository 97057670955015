import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDbRAjVqwBz0xMvFANhpSeVUNQRg2CMSp4",
    authDomain: "abcd-7cd89.firebaseapp.com",
    projectId: "abcd-7cd89",
    storageBucket: "abcd-7cd89.appspot.com",
    messagingSenderId: "757719171645",
    appId: "1:757719171645:web:111e67d960d677ce3c6054",
    measurementId: "G-W78CJW9VEJ"
};

// Initialisez Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

// Initialisez Firestore
const firestore = firebase.firestore();

export { firestore };
export default firebase;