import React, { useEffect, useState } from 'react';
import firebase from "../../firebaseConfig";
import "../../styles/generalTabs.css";
import {TiDelete, TiPlus} from "react-icons/ti";
import {hr} from "date-fns/locale";
import ReactQuill from "react-quill";
import { AutoComplete } from 'primereact/autocomplete';
import { InputText } from 'primereact/inputtext';
import "primereact/resources/themes/lara-light-indigo/theme.css";

const Logistics = ({ user }) => {
    const [modalActive, setModalActive] = useState(false);
    const [logisticsData, setLogisticsData] = useState([]);
    const [filterLocation, setFilterLocation] = useState('');
    const allLocations = logisticsData.map((item) => item.location);
    const uniqueLocations = [...new Set(allLocations)]; // Supprime les doublons
    const [suggestions, setSuggestions] = useState(uniqueLocations);
    const [editLogistic, setEditLogistic] = useState(null);

    useEffect(() => {
        const fetchInputValue = async () => {
            try {
                if (user) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data().Logistics_8;
                        setLogisticsData(data);
                    }
                }
            } catch (error) {
                console.log(error);
                // Gérer les erreurs de récupération des données
            }
        };
        fetchInputValue();
    }, [user]);

    const updateLogisticsData = (index, field, value) => {
        setLogisticsData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index][field] = value;
            return updatedData;
        });
    };

    const closeModal = () => {
        editLogistic ? setEditLogistic(null) : setModalActive(false)
    };

    const saveLogisticsData = async () => {
        try {
            if (user) {
                const userId = firebase.auth().currentUser.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                await docRef.update({ Logistics_8: logisticsData });
            }
        } catch (error) {
            console.log("Error saving data:", error);
            // Gérer les erreurs de sauvegarde des données
        }
    };
    const createNewLogistic = async (country,city) => {
        try {
            const userId = firebase.auth().currentUser.uid;
            const docRef = firebase.firestore().collection('users').doc(userId);
            const newLogistic = {
                location: `${country}`,
                city: `${city}`,
                housing: '',
                workingLocations: '',
                cafes: '',
                snacks: '',
                trainingSpots: '',
                shopping: '',
                commutingTraveling: '',
                hangOuts: '',
            };

            await docRef.update({
                Logistics_8: firebase.firestore.FieldValue.arrayUnion(newLogistic)
            });
            //setFilterLocation('')
            // Mettre à jour l'état avec le nouveau logistic
            setLogisticsData(prevData => [...prevData, newLogistic]);

            closeModal(); // Fermer la modale après avoir créé le logistic
        } catch (error) {
            console.log("Error creating new logistic:", error);
            // Gérer les erreurs de création du logistic
        }
    };
    const handleDelete = async (index) => {
        const updatedData = [...logisticsData];
        updatedData.splice(index, 1);
        setLogisticsData(updatedData);

        try {
            if (user) {
                const userId = firebase.auth().currentUser.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                await docRef.update({ Logistics_8: updatedData });
            }
        } catch (error) {
            console.log("Error saving data:", error);
            // Gérer les erreurs de sauvegarde des données
        }
    };

    const DisplayModal = () => {
        const [country, setCountry] = useState(editLogistic?.location || '');
        const [city, setCity] = useState(editLogistic?.city || '');
        const [suggestionsCountry, setSuggestionsCountry] = useState(null);
        const handleCreateLogistic = () => {
            createNewLogistic(country, city);
            setCountry('');
            setCity('');
            closeModal();
        };

        const handleSaveLogistic = () => {
            if (editLogistic) {
                // Recherche l'index de l'élément en cours de modification
                const index = logisticsData.findIndex((item) => item === editLogistic);

                if (index !== -1) {
                    // Modifie directement le champ country et city de l'élément
                    logisticsData[index].location = country;
                    logisticsData[index].city = city;

                    // Force la mise à jour de l'état
                    setLogisticsData([...logisticsData]);

                    // Enregistre les modifications
                    saveLogisticsData();

                    // Réinitialise les champs de country et city
                    setCountry('');
                    setCity('');

                    // Ferme la modale
                    closeModal();
                }
            }
        };

        const fetchCountries = async (e) => {

        };

        if (modalActive || editLogistic) {
            return (
                <div className="modal fade show modal-overlay" style={{ display: "block" }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                { editLogistic  ? <h5 className="modal-title">EDIT LOGISTIC</h5> : <h5 className="modal-title">NEW LOGISTIC</h5> }
                                <button className="btn-close" type="button" aria-label="Close" onClick={closeModal}></button>
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <div className={"containerBtnChoiceLogistics d-flex flex-column"}>
                                        <label htmlFor="countryId">Country</label>
                                        <AutoComplete
                                            value={country}
                                            suggestions={suggestionsCountry}
                                            placeholder="Select a country"
                                            completeMethod={async (e) => {
                                                const response = await fetch(`https://restcountries.com/v3.1/all`);
                                                const data = await response.json();
                                                const countryNames = data.map((country) => country.name.common);
                                                const inputValue = e.query.trim().toLowerCase();
                                                const inputLength = inputValue.length;
                                                const filteredCountries = countryNames.filter(
                                                    (country) => country.toLowerCase().slice(0, inputLength) === inputValue
                                                );
                                                setSuggestionsCountry(filteredCountries.sort());
                                            }}
                                            dropdown
                                            onChange={(e) => setCountry(e.value)}
                                        />

                                        <label htmlFor="CityId" className={"mt-3"}>City</label>
                                        <InputText
                                            type="text"
                                            className=" mt-1"
                                            id={"CityId"}
                                            value={city}
                                            onChange={(e) => setCity(e.target.value)}
                                            required
                                        />
                                        { editLogistic  ?
                                            <button className="btn btn-primary mt-5" onClick={handleSaveLogistic}>Save</button>
                                            :
                                            <button className="btn btn-primary mt-5" onClick={handleCreateLogistic}>Create</button>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="tab-pane containerDashboardContent container-fluid" role="tabpanel" id="tab-8">
            <h5 className={"mt-3"}>
                <span className="introText">
                    "The line between disorder and order lies in logistics." - Sun Tzu
                </span>
            </h5>
            <div className="container">
                <div className="w-100 d-flex justify-content-end m-2">
                    <AutoComplete
                        value={filterLocation}
                        suggestions={suggestions}
                        placeholder="By country..."
                        completeMethod={(e) => {
                            const inputValue = e.query.trim().toLowerCase();
                            const inputLength = inputValue.length;
                            const filteredLocations = uniqueLocations.filter(
                                (location) =>
                                    location.toLowerCase().slice(0, inputLength) === inputValue
                            );
                            setSuggestions(filteredLocations.sort());
                        }}
                        onChange={(e) => {
                            setFilterLocation(e.value);
                        }}
                        dropdown
                    />
                </div>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    {logisticsData.map((item, index) => (
                        item.location.includes(filterLocation) ? (
                            <div key={index} className="col p-1">
                                <div className="card">
                                    <div className="card-header text-center d-flex justify-content-center align-items-center" style={{ minHeight: '5em', cursor: "pointer" }} onClick={() => setEditLogistic(item)}>
                                        <div>
                                            <h3>{item.location}</h3>
                                            <h6>{item.city}</h6>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <LogisticItem title={"Housing"} content={item.housing} field={"housing"} index={index} saveLogisticsData={saveLogisticsData} updateLogisticsData={updateLogisticsData}/>
                                            <LogisticItem title={"Work locations"} content={item.workingLocations} field={"workingLocations"} index={index} saveLogisticsData={saveLogisticsData} updateLogisticsData={updateLogisticsData}/>
                                            <LogisticItem title={"Cafés & tea houses"} content={item.cafes} field={"cafes"} index={index} saveLogisticsData={saveLogisticsData} updateLogisticsData={updateLogisticsData}/>
                                            <LogisticItem title={"Restaurants & snack locations"} content={item.snacks} field={"snacks"} index={index} saveLogisticsData={saveLogisticsData} updateLogisticsData={updateLogisticsData}/>
                                            <LogisticItem title={"Grocery, supplements, pharmacy shopping"} content={item.shopping} field={"shopping"} index={index} saveLogisticsData={saveLogisticsData} updateLogisticsData={updateLogisticsData}/>
                                            <LogisticItem title={"Training spots (gyms, calisthenics parcs, outdoors)"} content={item.trainingSpots} field={"trainingSpots"} index={index} saveLogisticsData={saveLogisticsData} updateLogisticsData={updateLogisticsData}/>
                                            <LogisticItem title={"Social venues"} content={item.hangOuts} field={"hangOuts"} index={index} saveLogisticsData={saveLogisticsData} updateLogisticsData={updateLogisticsData}/>
                                            <LogisticItem title={"Commuting and travelling means + time"} content={item.commutingTraveling} field={"commutingTraveling"} index={index} saveLogisticsData={saveLogisticsData} updateLogisticsData={updateLogisticsData}/>
                                            <TiDelete className={"text-danger deleteBtnLogistics"} onClick={() => handleDelete(index)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null
                    ))}
                </div>

                <div className="w-100 d-flex justify-content-center">
                    <TiPlus style={{ fontSize: "1.8em", cursor: "pointer" }} className={"text-primary"} onClick={() => setModalActive(true)} />
                </div>
                <DisplayModal closeModal={closeModal} />
            </div>
        </div>
    );
};

export default Logistics;

const LogisticItem = ({ title, content, field, index, updateLogisticsData, saveLogisticsData, isHeader=false }) => {
    const [localContent, setLocalContent] = useState(content);

    const handleChange = (value) => {
        setLocalContent(value);
    };

    const handleBlur = () => {
        if (localContent !== content) {
            // Vérifier si le contenu a été modifié avant de mettre à jour

            updateLogisticsData(index, field, localContent);
            saveLogisticsData(); // Sauvegarder les modifications
        }
    };

    return (
        <>
            {isHeader === true ?
            <div className="">
                <ReactQuill
                    value={localContent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    modules={{ toolbar: [],clipboard: {
                            matchVisual: false,
                        }
                    }}
                    style={{ textAlign: "center" }}
                    className={"LogisticHeader"}
                />

            </div>
            :
            <div className="form-floating">
                <ReactQuill
                    value={localContent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    modules={{ toolbar: [],clipboard: {
                            matchVisual: true,
                        }
                    }}
                    style={{ position: "relative", top: "2em", marginBottom: ".1em" }}
                />
                <label className={"fw-bold text-decoration-underline text-wrap"}>{title}</label>
                <div className="m-4"></div>

            </div>
            }
        </>
    );
};


