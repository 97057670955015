import React, { useEffect, useRef, useState } from 'react';
import firebase from "../../firebaseConfig";
import "../../styles/generalTabs.css";
import {TiDelete, TiPlus} from "react-icons/ti";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const FlowRoutines = ({ user }) => {
    const [flowRoutinesData, setFlowRoutinesData] = useState([{}, {}, {}, {}, {}]);
    useEffect(() => {
        const fetchInputValue = async () => {
            try {
                if (user) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data().FlowRoutines_4;
                        setFlowRoutinesData(data);
                    }
                }
            } catch (error) {
                console.log(error);
                // Gérer les erreurs de récupération des données
            }
        };
        fetchInputValue();
    }, [user]);

    const updateflowRoutinesData = (index, field, value) => {
        setFlowRoutinesData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index][field] = value;
            return updatedData;
        });
    };

    const saveFlowRoutinesData = async () => {
        try {
            if (user) {
                const userId = firebase.auth().currentUser.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                await docRef.update({ FlowRoutines_4: flowRoutinesData });
            }
        } catch (error) {
            console.log("Error saving data:", error);
            // Gérer les erreurs de sauvegarde des données
        }
    };

    const createNewMap = async () => {
        const updatedData = [...flowRoutinesData];
        updatedData.push({});
        setFlowRoutinesData(updatedData);
    };

    const handleDelete = async (index) => {
        const updatedData = [...flowRoutinesData];
        updatedData.splice(index, 1);
        setFlowRoutinesData(updatedData);
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            saveFlowRoutinesData();
        }, 1000); // Définissez ici le délai de temporisation souhaité (en millisecondes)

        return () => {
            clearTimeout(timeoutId);
        };
    }, [flowRoutinesData]);
    return (
        <div className="tab-pane containerDashboardContent container-fluid" role="tabpanel" id="tab-4">
            <h5 className={"mt-3"}>
                <span className="introText">
                    "We are what we repeatedly do." - Aristotle
                </span>
            </h5>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.3em" }}>DOMINO EFFECT</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.3em" }}>EXECUTION</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.3em" }}>CREATIVITY</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.3em" }}>TEAM WORK</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.3em" }}>STUDYING / SKILL ACQUISITION</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.3em" }}>STAGE PERFORMANCE / COMPETITION</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.3em" }}>PHYSICAL TRAINING</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.3em" }}>SOCIAL</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.3em" }}>COMMUNICATION / SPEECH</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.3em" }}>BORING / NASTY</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            flowRoutinesData?.map((item, index) => (
                            <tr key={index}>
                                <td><FlowRoutinesItem content={item.domino} field={"domino"} index={index} saveFlowRoutinesData={saveFlowRoutinesData} updateflowRoutinesData={updateflowRoutinesData}/></td>
                                <td><FlowRoutinesItem content={item.execution} field={"execution"} index={index} saveFlowRoutinesData={saveFlowRoutinesData} updateflowRoutinesData={updateflowRoutinesData}/></td>
                                <td><FlowRoutinesItem content={item.creativity} field={"creativity"} index={index} saveFlowRoutinesData={saveFlowRoutinesData} updateflowRoutinesData={updateflowRoutinesData}/></td>
                                <td><FlowRoutinesItem content={item.teamWork} field={"teamWork"} index={index} saveFlowRoutinesData={saveFlowRoutinesData} updateflowRoutinesData={updateflowRoutinesData}/></td>
                                <td><FlowRoutinesItem content={item.skill} field={"skill"} index={index} saveFlowRoutinesData={saveFlowRoutinesData} updateflowRoutinesData={updateflowRoutinesData}/></td>
                                <td><FlowRoutinesItem content={item.performance} field={"performance"} index={index} saveFlowRoutinesData={saveFlowRoutinesData} updateflowRoutinesData={updateflowRoutinesData}/></td>
                                <td><FlowRoutinesItem content={item.training} field={"training"} index={index} saveFlowRoutinesData={saveFlowRoutinesData} updateflowRoutinesData={updateflowRoutinesData}/></td>
                                <td><FlowRoutinesItem content={item.social} field={"social"} index={index} saveFlowRoutinesData={saveFlowRoutinesData} updateflowRoutinesData={updateflowRoutinesData}/></td>
                                <td><FlowRoutinesItem content={item.speach} field={"speach"} index={index} saveFlowRoutinesData={saveFlowRoutinesData} updateflowRoutinesData={updateflowRoutinesData}/></td>
                                <td><FlowRoutinesItem content={item.boring} field={"boring"} index={index} saveFlowRoutinesData={saveFlowRoutinesData} updateflowRoutinesData={updateflowRoutinesData}/></td>
                                <TiDelete style={{ fontSize: "2.3em", cursor:"pointer" }} className={"text-danger mt-4"} onClick={() => handleDelete(index)}/>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="w-100 d-flex justify-content-center">
                <TiPlus style={{ fontSize: "1.8em", cursor:"pointer" }} className={"text-primary"} onClick={createNewMap}/>
            </div>
        </div>
    );
};

export default FlowRoutines;

const FlowRoutinesItem = ({ content, field, index, updateflowRoutinesData }) => {

    const handleChange = (value) => {
        updateflowRoutinesData(index, field, value);
    };

    return (
        <ReactQuill
            value={content}
            onChange={handleChange}
            modules={{ toolbar: []}}
        />
    );
};