import React, { useEffect, useState } from 'react';
import { TiPencil } from "react-icons/ti";
import CustomModal from "./CustomModal";
import firebase from "../../firebaseConfig";
import "../../styles/generalTabs.css"

const Goals = ({ user }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [displayContent, setDisplayContent] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [goalsData, setGoalsData] = useState({
        experience_1: '',
        globalView_1: '',
        intentions_1: '',
        in10x_1: '',
        globalView_2: '',
        intentions_2: '',
        globalView_3: '',
        intentions_3: '',
        processGoals_4: '',
        values_5: '',
    });

    useEffect(() => {
        const fetchInputValue = async () => {
            try {
                if (user) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data().Goals_1;
                        setGoalsData(data);
                    }
                }
            } catch (error) {
                console.log(error);
                // Gérer les erreurs de récupération des données
            }
        };
        fetchInputValue();
    }, [user]);

    const updateInputValue = async (newValue) => {
        try {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);

                // Créer un objet avec le champ spécifié et sa nouvelle valeur
                const updatedData = {
                    ["Goals_1." + fieldName]: newValue
                };

                // Mettre à jour l'état dynamiquement
                setGoalsData(prevState => ({
                    ...prevState,
                    [fieldName]: newValue
                }));

                if (modalIsOpen) setModalIsOpen(false);
                await docRef.update(updatedData);
            }
        } catch (error) {
            console.log(error);
            // Gérer les erreurs de mise à jour des données
        }
    };

    const openModal = (fieldName, title) => {
        setDisplayContent(goalsData[fieldName]);
        setFieldName(fieldName);
        setModalTitle(title);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="tab-pane containerDashboardContent container-fluid" role="tabpanel" id="tab-1">
            <h5 className={"mt-3"}>
            <span className="introText">
                "Without dreams and goals, there is no living, only merely existing, and that is not why we are here." - Mark Twain
            </span>
            </h5>

            <div className="container mt-5">
                <div className="row">
                    <div className="col col-xs-12 col-sm-12 col-md-3 col-lg-3">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-dream-tab" data-toggle="pill" href="#v-pills-dream" role="tab" aria-controls="v-pills-dream" aria-selected="true">LIFE MISSION / DREAM / PERSONAL PROJECT</a>
                            <a className="nav-link" id="v-pills-end-tab" data-toggle="pill" href="#v-pills-end" role="tab" aria-controls="v-pills-end" aria-selected="false">LONG-TERM 3-5 YEARS</a>
                            <a className="nav-link" id="v-pills-performance-tab" data-toggle="pill" href="#v-pills-performance" role="tab" aria-controls="v-pills-performance" aria-selected="false">SHORT-TERM 1 YEAR</a>
                            <a className="nav-link" id="v-pills-process-tab" data-toggle="pill" href="#v-pills-process" role="tab" aria-controls="v-pills-process" aria-selected="false">SMART GOALS</a>
                            <a className="nav-link" id="v-pills-values-tab" data-toggle="pill" href="#v-pills-values" role="tab" aria-controls="v-pills-values" aria-selected="false">CORE VALUES</a>
                        </div>
                    </div>
                    <div className="col col-xs-12 col-sm-12 col-md-9 col-lg-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-dream" role="tabpanel" aria-labelledby="v-pills-dream-tab">
                                <Card openModal={openModal} title={"Describe your dream goal, personal project & life mission in rich detail (what):"} fieldName={"globalView_1"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"My intentions and commitments with precise outcomes in the 1st person. (how):"} fieldName={"intentions_1"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"If I were to 10x my dream, what would it look like? (Increasing motivation & drive):"} fieldName={"in10x_1"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"What are you seeking to experience, and satisfy by realizing your dream goal?"} fieldName={"experience_1"} goalsData={goalsData} />
                            </div>
                            <div className="tab-pane fade" id="v-pills-end" role="tabpanel" aria-labelledby="v-pills-end-tab">
                                <Card openModal={openModal} title={"Describe your long-term goals in rich detail (what):"} fieldName={"globalView_2"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"My intentions and commitments with precise outcomes in the 1st person. (how):"} fieldName={"intentions_2"} goalsData={goalsData} />
                            </div>
                            <div className="tab-pane fade" id="v-pills-performance" role="tabpanel" aria-labelledby="v-pills-performance-tab">
                                <Card openModal={openModal} title={"Describe your short-therm goals in rich detail (what):"} fieldName={"globalView_3"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"My intentions and commitments with precise outcomes in the 1st person. (how):"} fieldName={"intentions_3"} goalsData={goalsData} />
                            </div>
                            <div className="tab-pane fade" id="v-pills-process" role="tabpanel" aria-labelledby="v-pills-process-tab">
                                <Card openModal={openModal} title={"Write you smart goals list"} fieldName={"processGoals_4"} goalsData={goalsData} />
                            </div>
                            <div className="tab-pane fade" id="v-pills-values" role="tabpanel" aria-labelledby="v-pills-values-tab">
                                <Card openModal={openModal} title={"Name 3 to 10 values behind everything you do"} fieldName={"values_5"} goalsData={goalsData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modalIsOpen && (
                <CustomModal
                    title={modalTitle}
                    closeModal={closeModal}
                    content={displayContent}
                    isOpen={modalIsOpen}
                    fieldName={fieldName}
                    saveField={updateInputValue}
                />
            )}
        </div>
    );
};

const Card = ({ openModal, title, fieldName, goalsData }) => {
    return (
        <div className="mb-5 fondGris">
            <p style={{ cursor: "pointer" }} onClick={() => openModal(fieldName, title)}>
                <span style={{ textDecoration: "underline" }}>{title}</span>
                <TiPencil style={{ marginLeft: "0.5em" }} />
            </p>
            <div onDoubleClick={() => openModal(fieldName, title)} className="contentText">
                {goalsData[fieldName]}
            </div>
        </div>
    );

};


export default Goals;
