import React, {useEffect, useState} from 'react';
import '../styles/Tutorials.css';
import DashBoardNavBar from "./DashboardNavbar";
import { useNavigate } from "react-router-dom";
import firebase from "../firebaseConfig";

const Feedback = ({ user }) => {
    const [userName, setUserName] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        // Vérifier si l'utilisateur dispose d'un abonnement valide
        const checkPlanValidity = async () => {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    const data = docSnapshot.data();
                    setUserName(data.username)
                }
            }else {
                // Utilisateur non connecté, gérer le cas approprié ici si nécessaire
                // Par exemple, rediriger vers la page de connexion
                navigate('/login');
            }
        };
        checkPlanValidity();
    }, []);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (user) {
            const userId = user.uid;
            console.log(userId)
            // Envoi des données à la collection "feedbacks" dans Firestore
            await firebase.firestore().collection('feedbacks').add({
                userId: userId,
                userName: userName,
                title: title,
                description: description,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
            });

            // Réinitialisation des champs du formulaire
            setTitle('');
            setDescription('');

            // Mettre à jour l'état pour indiquer que le feedback a été soumis
            setFeedbackSubmitted(true);
        }
    };

    return (
        <>
            <DashBoardNavBar userName={userName} boldItem={"feedback"} />
            <div className="container">
                <h1 className="text-center mt-5 mb-3">REPORT A BUG OR GIVE A FEEDBACK</h1>
                <p className="text-center">
                    We value your feedback! If you've encountered a bug or have any suggestions to improve our application,
                    please let us know using the form below. Your input helps us enhance the user experience and deliver a better product.
                </p>

                {feedbackSubmitted && (
                    <p className="text-success">Feedback submitted successfully!</p>
                )}
                <form onSubmit={handleFormSubmit}>
                    <div className="mb-3">
                        <label htmlFor="title" className="form-label">Feedback Title</label>
                        <input type="text" className="form-control" id="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Feedback Description</label>
                        <textarea className="form-control" id="description" rows="4" value={description} onChange={(e) => setDescription(e.target.value)} required />
                    </div>
                    <button type="submit" className="btn btn-primary">Submit Feedback</button>
                </form>
            </div>
        </>
    );
};

export default Feedback;
