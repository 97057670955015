import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from './firebaseConfig';
import HomePage from './components/HomePage';
import LoginForm from './components/LoginForm';
import Dashboard from './components/Dashboard';
import Register from "./components/Register";
import Subscription from "./components/Subscription";
import FlowTracker from "./components/FlowTracker";
import PersonnalTraining from "./components/FlowTracker/PersonnalTraining";
import Contact from "./components/Contact";
import Tutorials from "./components/Tutorials";
import Feedback from "./components/Feedback";
import Welcome from "./components/Welcome";
import EmailSent from "./components/emailSent";
import Pricing from "./components/Pricing";
import AboutUs from "./components/About";
import Account from "./components/Account";
import Admin from "./components/Admin";
import UnauthorizedPage from "./components/UnauthorizedPage";
import Cycles from "./components/Cycles";


function App() {
    const [user, loading] = useAuthState(firebase.auth());

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage user={user}/>} />
                <Route path="/emailSent" element={<EmailSent/>} />
                <Route path="/register" element={<Register />} />
                <Route path="/subscription" element={<Subscription />} />
                <Route path="/login" element={<LoginForm />} />
                <Route path="/About" element={<AboutUs user={user}/>} />
                <Route path="/contact" element={<Contact user={user} />} />
                <Route path="/pricing" element={<Pricing user={user} />} />
                <Route path="/welcome" element={<Welcome user={user} />} />
                <Route path="/unauthorized" element={<UnauthorizedPage/>} />
                <Route
                    path="/dashboard"
                    element={
                        user ? (
                            user.emailVerified ? (
                                <Dashboard user={user} />
                            ) : (
                                <Navigate to="/emailSent" />
                            )
                        ) : (
                            <Navigate to="/unauthorized" />
                        )
                    }
                />
                <Route path="/cycles" element={user ? user.emailVerified ? <Cycles user={user} /> : <EmailSent/>: <UnauthorizedPage/>} />
                <Route path="/tutorials" element={user ? user.emailVerified? <Tutorials user={user} /> : <EmailSent/>: <UnauthorizedPage/>} />
                <Route path="/feedback" element={user ? user.emailVerified? <Feedback user={user} /> : <EmailSent/>: <UnauthorizedPage/>} />
                <Route path="/flowtracker" element={user ? user.emailVerified? <FlowTracker user={user} /> : <EmailSent/>: <UnauthorizedPage/>} />
                <Route path="/personnalTraining" element={user ? user.emailVerified ? <PersonnalTraining user={user} /> : <EmailSent/>: <UnauthorizedPage/>} />
                <Route path="/account" element={user ? user.emailVerified ? <Account user={user} /> : <EmailSent/>: <UnauthorizedPage/>} />
                <Route path="/adminpanel" element={user ? user.emailVerified ? <Admin user={user} /> : <EmailSent/>: <UnauthorizedPage/>} />
                <Route path="/personnalTraining" element={user ? user.emailVerified? <PersonnalTraining user={user} /> : <EmailSent/>: <UnauthorizedPage/>} />
            </Routes>
        </Router>
    );
}

export default App;