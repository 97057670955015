import firebase from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import "../styles/flowTracker.css"
import React, {useEffect, useState} from "react";
import DailyRoutine from "./FlowTracker/DailyRoutine";
import Performance from "./FlowTracker/Performance";
import DashBoardNavBar from "./DashboardNavbar";
import NoSubscription from "./NoSubscription";

function FlowTracker({ user }) {
    const navigate = useNavigate();
    const [planValidity, setPlanValidity] = useState(0);
    const [userName, setUserName] = useState(false);

    useEffect(() => {
        // Vérifier si l'utilisateur dispose d'un abonnement valide
        const checkPlanValidity = async () => {
            if (user){
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    const data = docSnapshot.data();
                    const endSubscriptionDate = data.endSubscriptionDate.toDate();
                    setUserName(data.username)
                    if (endSubscriptionDate > new Date()) {
                        setPlanValidity(2);
                    } else {
                        setPlanValidity(1);
                    }
                }
            }else{
                navigate('/login')
            }
        };
        checkPlanValidity();
    }, []);


    const handleLogout = async () => {
        try {
            await firebase.auth().signOut();
            navigate('/');
        } catch (error) {
            // Gérer les erreurs de déconnexion
        }
    };

    return (
        <>
            { planValidity === 2 &&
                <>
                    <DashBoardNavBar userName={userName} boldItem={'flowTracker'}/>

                    <section className="position-relative pt-4">
                        <div className="container mainContainer">
                            <div>
                                <ul className="nav mainTabs" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" role="tab" data-bs-toggle="tab" href="#1-goals">
                                            DAILY ROUTINE
                                        </a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" role="tab" data-bs-toggle="tab" href="#2-single">
                                            FLOW CHARTS
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="1-goals" role="tabpanel">
                                        <DailyRoutine user={user}/>
                                    </div>
                                    <div className="tab-pane fade" id="2-single" role="tabpanel">
                                        <Performance user={user}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            }
            { planValidity === 1 &&
                <NoSubscription/>
            }
            <div className="mt-5"></div>
        </>
    );
}

export default FlowTracker;