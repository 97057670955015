import React, { useEffect, useState } from 'react';
import firebase from "../../firebaseConfig";
import DayPicker from "./DayPicker";
import moment from "moment";
import ReactQuill from "react-quill";
import toast from "react-simple-toasts";


const DailyRoutine = ({ user }) => {
    const [day, setDay] = useState(null);
    const [dateData, setDateData] = useState([]);
    const [dateDataIndex, setDateDataIndex] = useState(null);
    const [isModified, setIsModified] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [nightPerson, setNightPerson] = useState(false);

    useEffect(() => {
        const fetchDateData = async () => {
            try {
                setIsLoadingData(true);
                if (user && day) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const _nightPerson = docSnapshot.data().nightPerson;
                        setNightPerson(_nightPerson)
                        const allData = docSnapshot.data().FlowTrackerData;
                        const filteredData = allData?.filter(
                            (data) => data.date.toDate().toLocaleDateString() === day.toLocaleDateString()
                        );
                        if (filteredData.length > 0) {
                            setDateData(filteredData[0].data);
                            setDateDataIndex(allData.indexOf(filteredData[0]));
                        } else {
                            const newData = {
                                date: day,
                                data: {
                                    category_1: "",
                                    category_2: "",
                                    category_3: "",
                                    category_4: "",
                                    category_5: "",
                                    category_6: "",
                                    category_7: "",
                                    notation_1: "",
                                    notation_2: "",
                                    notation_3: "",
                                    notation_4: "",
                                    notation_5: "",
                                    notation_6: "",
                                    notation_7: "",
                                    time1_0: "00:00",
                                    time1_1: "00:00",
                                    time2_0: "00:00",
                                    time2_1: "00:00",
                                    time3_0: "00:00",
                                    time3_1: "00:00",
                                    time4_0: "00:00",
                                    time4_1: "00:00",
                                    time5_0: "00:00",
                                    time5_1: "00:00",
                                    time6_0: "00:00",
                                    time6_1: "00:00",
                                    time7_0: "00:00",
                                    time7_1: "00:00",
                                    grandTotal:"00:00",
                                    meanFlowQuality:0
                                }
                            };
                            docRef.update({
                                FlowTrackerData: firebase.firestore.FieldValue.arrayUnion(newData)
                            });
                            fetchDateData()
                        }
                    }
                }
                setIsLoadingData(false);
            } catch (error) {
                console.log(error);
                // Gérer les erreurs de récupération des données
            }
        };
        fetchDateData();
    }, [user, day]);

    const saveFlowBlockData = () => {
        try {
            if (user && day && dateDataIndex !== null) {
                const userId = firebase.auth().currentUser.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);

                docRef.get().then((docSnapshot) => {
                    if (docSnapshot.exists) {
                        const allData = docSnapshot.data().FlowTrackerData;
                        const updatedData = [...allData];
                        const existingObject = updatedData[dateDataIndex];

                        if (existingObject) {
                            existingObject.data = dateData;
                            updatedData[dateDataIndex] = existingObject;
                            toast("Saved")
                            docRef.update({ FlowTrackerData: updatedData });
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error);
            // Gérer les erreurs de sauvegarde des données
        }
    };

    useEffect(() => {
        if (dateData) {
            processTotalTime(dateData);
        }
    }, [
        dateData.time1_0,
        dateData.time1_1,
        dateData.time2_0,
        dateData.time2_1,
        dateData.time3_0,
        dateData.time3_1,
        dateData.time4_0,
        dateData.time4_1,
        dateData.time5_0,
        dateData.time5_1,
        dateData.time6_0,
        dateData.time6_1,
        dateData.time7_0,
        dateData.time7_1,
        dateData.nightTask_1,
        dateData.nightTask_2,
        dateData.nightTask_3,
        dateData.nightTask_4,
        dateData.nightTask_5,
        dateData.nightTask_6,
        dateData.nightTask_7,

    ]);

    useEffect(() => {
        if (dateData) {
            processMeanFlowQuality(dateData);
        }
    }, [
        dateData.notation_1,
        dateData.notation_2,
        dateData.notation_3,
        dateData.notation_4,
        dateData.notation_5,
        dateData.notation_6,
        dateData.notation_7,
    ]);

    const processTotalTime = () => {

        const timePairs = [
            ["time1_0", "time1_1", "nightTask_1"],
            ["time2_0", "time2_1", "nightTask_2"],
            ["time3_0", "time3_1", "nightTask_3"],
            ["time4_0", "time4_1", "nightTask_4"],
            ["time5_0", "time5_1", "nightTask_5"],
            ["time6_0", "time6_1", "nightTask_6"],
            ["time7_0", "time7_1", "nightTask_7"],
        ];

        // Gestion Grand Total
        let totalDuration = moment.duration(0);
        let hasCalculationError = false;
        timePairs.forEach(([startTimeKey, endTimeKey, nightTaskKey]) => {
            const startTime = moment(dateData[startTimeKey], "HH:mm");
            const endTime = moment(dateData[endTimeKey], "HH:mm");
            const isNightTask = dateData[nightTaskKey];
            if (isNightTask) {
                if (endTime.isAfter(startTime)) {
                    hasCalculationError = true;
                } else if (endTime.isBefore(startTime)) {
                    let nightDuration = endTime.hours() - startTime.hours();
                    nightDuration += 24;
                    totalDuration.add(nightDuration, 'hours');
                }
            } else {
                if (endTime.isAfter(startTime)) {
                    const duration = moment.duration(endTime.diff(startTime));
                    totalDuration.add(duration);
                } else if (endTime.isBefore(startTime)) {
                    hasCalculationError = true;
                }
            }

            if (hasCalculationError) {
                setIsModified(false);
            }
        });

        const hours = Math.floor(totalDuration.asHours());
        const minutes = totalDuration.minutes();
        updateFlowBlockData("grandTotal", hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0"), false);
    }
    const processMeanFlowQuality = () => {
        const notations = [
            "notation_1",
            "notation_2",
            "notation_3",
            "notation_4",
            "notation_5",
            "notation_6",
            "notation_7"
        ];

        // Get the values of notations and calculate the mean
        let totalNotations = 0;
        let countNotations = 0;
        notations.forEach(notation => {
            const value = parseInt(dateData[notation], 10); // Assuming data is the object containing notation values
            if (!isNaN(value)) {
                totalNotations += value;
                countNotations++;
            }
        });

        const mean = countNotations > 0 ? totalNotations / countNotations : 0;
        const roundedMean = mean.toFixed(2); // Round the mean to two decimal places

        // Update the meanFlowQuality in your data object or state
        updateFlowBlockData("meanFlowQuality", roundedMean, false);
    }


    const updateFlowBlockData = (field, value, update = true) => {
        setDateData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
        if (update && !isLoadingData) {
            setIsModified(true);
        }
    };

    const handleSave = () => {
        if (!isLoadingData) {
            saveFlowBlockData(); // Appeler la fonction de sauvegarde
            setIsModified(false); // Réinitialiser l'état isModified à false
        }
    };


    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (isModified) {
                event.preventDefault();
                event.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isModified]);

    return (
        <div>
            <DayPicker returnedDate={setDay} onSave={handleSave} isModified={isModified}/>
            <div className={" align-items-center justify-content-center"}>
                <table className={"table mt-3"}>
                   <tbody>
                        <tr>
                            <td style={{backgroundColor:'#f8f8f8'}} className={"leftTd"}></td>
                            <td style={{backgroundColor:'#f8f8f8'}} className={"fw-bold flowBlockTdTitle"}>Flow block 1</td>
                            <td style={{backgroundColor:'#f8f8f8'}} className={"fw-bold flowBlockTdTitle"}>Flow block 2</td>
                            <td style={{backgroundColor:'#f8f8f8'}} className={"fw-bold flowBlockTdTitle"}>Flow block 3</td>
                            <td style={{backgroundColor:'#f8f8f8'}} className={"fw-bold flowBlockTdTitle"}>Flow block 4</td>
                            <td style={{backgroundColor:'#f8f8f8'}} className={"fw-bold flowBlockTdTitle"}>Flow block 5</td>
                            <td style={{backgroundColor:'#f8f8f8'}} className={"fw-bold flowBlockTdTitle"}>Flow block 6</td>
                            <td style={{backgroundColor:'#f8f8f8'}} className={"fw-bold flowBlockTdTitle"}>Flow block 7</td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Category</h5></td>
                            <CategorySelector updateFlowBlockData={updateFlowBlockData} field={"category_1"} data={dateData}/>
                            <CategorySelector updateFlowBlockData={updateFlowBlockData} field={"category_2"} data={dateData}/>
                            <CategorySelector updateFlowBlockData={updateFlowBlockData} field={"category_3"} data={dateData}/>
                            <CategorySelector updateFlowBlockData={updateFlowBlockData} field={"category_4"} data={dateData}/>
                            <CategorySelector updateFlowBlockData={updateFlowBlockData} field={"category_5"} data={dateData}/>
                            <CategorySelector updateFlowBlockData={updateFlowBlockData} field={"category_6"} data={dateData}/>
                            <CategorySelector updateFlowBlockData={updateFlowBlockData} field={"category_7"} data={dateData}/>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Task / Activity</h5></td>
                            <td><FlowBlockItem field={"task_1"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"task_2"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"task_3"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"task_4"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"task_5"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"task_6"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"task_7"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Block starting time</h5></td>
                            <td className={"centeredTd"}><TimeManager number={1} type={0} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager number={2} type={0} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager number={3} type={0} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager number={4} type={0} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager number={5} type={0} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager number={6} type={0} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager number={7} type={0} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Specific / personnal triggers</h5></td>
                            <td><FlowBlockItem field={"triggers_1"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"triggers_2"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"triggers_3"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"triggers_4"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"triggers_5"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"triggers_6"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"triggers_7"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Warm-up sequences</h5></td>
                            <td><FlowBlockItem field={"sequences_1"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"sequences_2"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"sequences_3"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"sequences_4"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"sequences_5"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"sequences_6"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"sequences_7"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Distractions</h5></td>
                            <td><FlowBlockItem field={"distractions_1"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"distractions_2"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"distractions_3"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"distractions_4"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"distractions_5"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"distractions_6"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"distractions_7"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Interruptions</h5></td>
                            <td><FlowBlockItem field={"interruptions_1"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"interruptions_2"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"interruptions_3"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"interruptions_4"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"interruptions_5"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"interruptions_6"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"interruptions_7"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Friction Agents</h5></td>
                            <td><FlowBlockItem field={"agents_1"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"agents_2"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"agents_3"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"agents_4"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"agents_5"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"agents_6"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"agents_7"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Release Activities</h5></td>
                            <td><FlowBlockItem field={"release_1"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"release_2"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"release_3"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"release_4"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"release_5"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"release_6"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"release_7"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Recovery activities</h5></td>
                            <td><FlowBlockItem field={"recovery_1"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"recovery_2"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"recovery_3"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"recovery_4"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"recovery_5"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"recovery_6"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"recovery_7"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Extra notes</h5></td>
                            <td><FlowBlockItem field={"notes_1"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"notes_2"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"notes_3"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"notes_4"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"notes_5"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"notes_6"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td><FlowBlockItem field={"notes_7"} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Block ending time</h5></td>
                            <td className={"centeredTd"}><TimeManager nightPerson={nightPerson} number={1} type={1} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager nightPerson={nightPerson} number={2} type={1} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager nightPerson={nightPerson} number={3} type={1} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager nightPerson={nightPerson} number={4} type={1} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager nightPerson={nightPerson} number={5} type={1} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager nightPerson={nightPerson} number={6} type={1} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"centeredTd"}><TimeManager nightPerson={nightPerson} number={7} type={1} updateFlowBlockData={updateFlowBlockData} data={dateData}/></td>
                            <td className={"text-center"}><h5>Total</h5></td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Flow block total time</h5></td>
                            <td className={"centeredTd"}><TotalTime number={1} data={dateData}/></td>
                            <td className={"centeredTd"}><TotalTime number={2} data={dateData}/></td>
                            <td className={"centeredTd"}><TotalTime number={3} data={dateData}/></td>
                            <td className={"centeredTd"}><TotalTime number={4} data={dateData}/></td>
                            <td className={"centeredTd"}><TotalTime number={5} data={dateData}/></td>
                            <td className={"centeredTd"}><TotalTime number={6} data={dateData}/></td>
                            <td className={"centeredTd"}><TotalTime number={7} data={dateData}/></td>
                            <td className={"centeredTd"}>{dateData.grandTotal}</td>
                        </tr>
                        <tr>
                            <td className={"leftTd"}><h5>Flow quality</h5></td>
                            <NotationSelector updateFlowBlockData={updateFlowBlockData} field={"notation_1"} data={dateData}/>
                            <NotationSelector updateFlowBlockData={updateFlowBlockData} field={"notation_2"} data={dateData}/>
                            <NotationSelector updateFlowBlockData={updateFlowBlockData} field={"notation_3"} data={dateData}/>
                            <NotationSelector updateFlowBlockData={updateFlowBlockData} field={"notation_4"} data={dateData}/>
                            <NotationSelector updateFlowBlockData={updateFlowBlockData} field={"notation_5"} data={dateData}/>
                            <NotationSelector updateFlowBlockData={updateFlowBlockData} field={"notation_6"} data={dateData}/>
                            <NotationSelector updateFlowBlockData={updateFlowBlockData} field={"notation_7"} data={dateData}/>
                            <td className={"centeredTd"}>{dateData.meanFlowQuality}</td>
                        </tr>
                   </tbody>
               </table>
           </div>
        </div>
    );

};

export default DailyRoutine;


const FlowBlockItem = ({ field, updateFlowBlockData, data })=> {
    // Initialiser la valeur du champ avec une chaîne vide si elle est indéfinie
    const fieldValue = data[field] || "";

    const handleChange = (value) => {

        updateFlowBlockData(field, value);
    };

    return (
        <ReactQuill
            value={fieldValue}
            onChange={handleChange}
            modules={{ toolbar: []}}
        />

    );
};



const TimeManager = ({ number, type, updateFlowBlockData, data, nightPerson }) => {
    const [styleWarning, setStyleWarning] = useState(""); // État local pour la classe de style
    const value = "time" + number + "_" + type;
    const [isNextDay, setIsNextDay] = useState(false);

    useEffect(() => {
        setIsNextDay(data["nightTask_" + number]);
    }, [data, number]);

    const handleTimeChange = (e) => {
        const { value } = e.target;
        if (value !== "") {
            const adjustedTime = isNextDay ? moment(value, "HH:mm").subtract(1, 'day').format("HH:mm") : value;
            updateFlowBlockData("time" + number + "_" + type, adjustedTime);
            checkDataValidity(adjustedTime);
        }
    };
    const handleCheckboxChange = (e) => {
        setIsNextDay(e.target.checked);
        updateFlowBlockData("nightTask_" + number, e.target.checked);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Backspace") {
            e.preventDefault(); // Empêcher la touche "Backspace" de supprimer le contenu de l'input

            // Extraire les heures et les minutes de la valeur actuelle
            updateFlowBlockData("time" + number + "_" + type, "00:00");
        }
    };

    const handleNowButtonClick = () => {
        const currentTime = moment().format("HH:mm");
        updateFlowBlockData("time" + number + "_" + type, currentTime);
        checkDataValidity(currentTime);
    };

    const checkDataValidity = (timeValue) => {
        if (type === 1) {
            const time1 = moment(data["time" + number + "_0"], "HH:mm");
            const time2 = moment(timeValue, "HH:mm");

            if (time2.isBefore(time1) && !(time1.isSame(time2, "minute"))) {
                if (isNextDay){
                    setStyleWarning("");
                }else {
                    setStyleWarning("bg-warning");
                }
            } else {
                if (isNextDay){
                    setStyleWarning("bg-warning");
                }else {
                    setStyleWarning("");
                }
            }
        }
    };

    useEffect(() => {
        checkDataValidity(data[value]);
    }, [data, number, type, value]);

    return (
        data[value] && (
            <div className="input-group btnTimeContainer">
                <input
                    type="time"
                    onChange={handleTimeChange}
                    value={data[value]}
                    onKeyDown={handleKeyDown} // Ajoutez ce gestionnaire d'événements
                    className={`form-control ${styleWarning}`}
                />
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleNowButtonClick}
                >
                    Now
                </button>
                { nightPerson &&
                    <div className="form-check mb-4">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="nightPersonCheckbox"
                            checked={isNextDay}
                            onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label" htmlFor="nightPersonCheckbox">Overnight activity</label>
                    </div>
                }
            </div>
        )
    );
};



const TotalTime = ({ number, data }) => {
    const [totalTime, setTotalTime] = useState("00:00");

    useEffect(() => {

        const time1 = moment(data["time" + number + "_0"], "HH:mm");
        const time2 = moment(data["time" + number + "_1"], "HH:mm");

        if (time2.isBefore(time1)) {
            if (data["nightTask_" + number] === true) {
                time2.add(24, 'hours');

                const duration = moment.duration(time2.diff(time1));
                const hours = Math.floor(duration.asHours());
                const minutes = duration.minutes();
                setTotalTime(hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0"));
            } else {
                if (!(time2.isSame("00:00", "HH:mm") && time1.isSame("00:00", "HH:mm"))) {
                    setTotalTime("Err.");
                }
            }
        } else {
            if (data["nightTask_" + number] === true) {
                if (!(time2.isSame("00:00", "HH:mm") && time1.isSame("00:00", "HH:mm"))) {
                    setTotalTime("Err.");
                }

            }
            else {
                const duration = moment.duration(time2.diff(time1));
                const hours = Math.floor(duration.asHours());
                const minutes = duration.minutes();
                setTotalTime(hours.toString().padStart(2, "0") + ":" + minutes.toString().padStart(2, "0"));
            }
        }

    }, [data["time" + number + "_0"], data["time" + number + "_1"], data["nightTask_" + number]]);

    return (
        <>
            {totalTime}
        </>
    );
};


const CategorySelector = ({ field, updateFlowBlockData, data })=> {
    const handleChange = (e) => {
        const { value } = e.target;
        updateFlowBlockData(field, value);
    };

    return (
        <td>
            <select className="form-select" aria-label="" value={data[field]} onChange={handleChange} >
                <option value="">Select a category</option>
                <option value="Workflow">Workflow/Execution/Productivity</option>
                <option value="Creativity/Arts">Creativity/Creation/Arts</option>
                <option value="Fitness/Sports">Fitness/Sports</option>
                <option value="Socializing/Communication/Public Speech">Socializing/Communication/Public Speaking</option>
                <option value="Education/Learning">Education/Learning/Skill acquisition</option>
                <option value="Reading">Reading</option>
            </select>
        </td>
    );
};

const NotationSelector = ({ field, updateFlowBlockData, data })=> {
    const handleChange = (e) => {
        const { value } = e.target;
        updateFlowBlockData(field, value);
    };

    return (
        <td className={"text-center"}>
            <select
                className={`form-select w-50`}
                aria-label=""
                value={data[field]}
                onChange={handleChange}
                style={{
                    backgroundColor:
                        data[field] === "1"
                            ? "#862800"
                            : data[field] === "2"
                                ? "#d34100"
                                : data[field] === "3"
                                    ? "#8822f5"
                                    : data[field] === "4"
                                        ? "#4066ff"
                                        : data[field] === "5"
                                            ? "#19c3ff"
                                            : "",
                    color: "black",
                }}
            >
                <option style={{backgroundColor: "white"}} value=""></option>
                <option style={{backgroundColor: "#862800"}} value={1}>1</option>
                <option style={{backgroundColor: "#d34100"}} value={2}>2</option>
                <option style={{backgroundColor: "#8822f5"}} value={3}>3</option>
                <option style={{backgroundColor: "#4066ff"}} value={4}>4</option>
                <option style={{backgroundColor: "#19c3ff"}} value={5}>5</option>
            </select>
        </td>
    );
};