import React, { useEffect, useState } from 'react';
import CustomModal from "./CustomModal";
import firebase from "../../firebaseConfig";
import "../../styles/generalTabs.css"

const FlowTrigger = ({ user }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [displayContent, setDisplayContent] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [flowTriggerData, setflowTriggerData] = useState({
        curiosity_1 : '',
        passion_1_2 : '',
        purpose_1_3 : '',
        concentration_2 : '',
        skill_3 : '',
        feedback_4 : '',
        richEnv_5 : '',
        deepEnv_6 : '',
        highEnv_7 : '',
        creativity_8 : '',
        triggers_9 : '',
        autonomy_10 : ''
    });

    useEffect(() => {
        const fetchInputValue = async () => {
            try {
                if (user) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data().FlowTrigger_3;
                        setflowTriggerData(data);
                    }
                }
            } catch (error) {
                console.log(error);
                // Gérer les erreurs de récupération des données
            }
        };
        fetchInputValue();
    }, [user]);

    const updateInputValue = async (newValue) => {
        try {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);

                // Créer un objet avec le champ spécifié et sa nouvelle valeur
                const updatedData = {
                    ["FlowTrigger_3." + fieldName]: newValue
                };

                // Mettre à jour l'état dynamiquement
                setflowTriggerData(prevState => ({
                    ...prevState,
                    [fieldName]: newValue
                }));

                if (modalIsOpen) setModalIsOpen(false);
                await docRef.update(updatedData);
            }
        } catch (error) {
            console.log(error);
            // Gérer les erreurs de mise à jour des données
        }
    };

    const openModal = (fieldName, title) => {
        setDisplayContent(flowTriggerData[fieldName]);
        setFieldName(fieldName);
        setModalTitle(title);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="tab-pane containerDashboardContent container-fluid" role="tabpanel" id="tab-3">
            <h5 className={"mt-3"}>
                <span className="introText">
                     "As soon as the whistle blows, something triggers me." - Caeleb Dressel
                </span>
            </h5>
            <div className="container">
                <div className="col">
                    <div className="row">
                        <Card openModal={openModal} title={"CURIOSITY"} subTitle={"WHAT ARE THEY AND HOW DO THEY CONNECT?"} fieldName={"curiosity_1"} flowTriggerData={flowTriggerData} />
                        <Card openModal={openModal} title={"PASSION"} subTitle={"WHAT IS YOUR PASSION?"} fieldName={"passion_1_2"} flowTriggerData={flowTriggerData} />
                        <Card openModal={openModal} title={"PURPOSE"} subTitle={"WHAT IS YOUR PURPOSE?"} fieldName={"purpose_1_3"} flowTriggerData={flowTriggerData} />
                        <Card openModal={openModal} title={"COMPLETE CONCENTRATION"} subTitle={"WHAT HELPS YOU REACH COMPLETE CONCENTRATION?"} fieldName={"concentration_2"} flowTriggerData={flowTriggerData} />
                        <Card openModal={openModal} title={"CHALLENGE-SKILLS BALANCE"} subTitle={"WHAT'S YOUR IDEAL RANGE  & HOW WILL YOU CHALLENGE YOURSELF TO REACH YOUR GOALS?"} fieldName={"skill_3"} flowTriggerData={flowTriggerData} />
                        <Card openModal={openModal} title={"IMMEDIATE FEEDBACK"} subTitle={"WHO & WHAT CAN GIVE YOU IMMEDIATE FEEDBACK?"} fieldName={"feedback_4"} flowTriggerData={flowTriggerData} />
                        <Card openModal={openModal} title={"RICH ENVIRONMENTS"} subTitle={""} fieldName={"richEnv_5"} flowTriggerData={flowTriggerData} />
                        <Card openModal={openModal} title={"DEEP EMBODIMENT"} subTitle={""} fieldName={"deepEnv_6"} flowTriggerData={flowTriggerData} />
                        <Card openModal={openModal} title={"HIGH CONSEQUENCES"} subTitle={""} fieldName={"highEnv_7"} flowTriggerData={flowTriggerData} />
                        <Card openModal={openModal} title={"CREATIVITY TRIGGERS"} subTitle={""} fieldName={"creativity_8"} flowTriggerData={flowTriggerData} />
                        <Card openModal={openModal} title={"PERSONAL TRIGGERS"} subTitle={"KINESTHETIC, BREATHWORK, AUDITORY, MENTAL, OLFACTORY, NOOTROPICS, ETC"} fieldName={"triggers_9"} flowTriggerData={flowTriggerData} />
                        <Card openModal={openModal} title={"AUTONOMY"} subTitle={""} fieldName={"autonomy_10"} flowTriggerData={flowTriggerData} />
                    </div>
                </div>

            </div>

            {modalIsOpen && (
                <CustomModal
                    title={modalTitle}
                    closeModal={closeModal}
                    content={displayContent}
                    isOpen={modalIsOpen}
                    saveField={updateInputValue}
                />
            )}
        </div>
    );
};

const Card = ({ openModal, title, subTitle, fieldName, flowTriggerData }) => {
    return (
        <div className="col-xl-6 col-xs-12 col-sm-6 mt-3" style={{ cursor: "pointer" }}>
            <div className="card">
                <div className="card-header text-center d-xxl-flex justify-content-xxl-center align-items-xxl-center" style={{ minHeight: "5em" }}>
                    <div onClick={() => openModal(fieldName, title)}>
                        <span style={{ textDecoration: "underline", cursor: "pointer", fontSize: "1.3em", fontWeight: 'bold'  }}>{title}</span>
                        {subTitle ? (
                            <p className="subtitleMaj d-xxl-flex justify-content-xxl-center align-items-xxl-center">{subTitle}</p>
                        ) : null}
                    </div>
                </div>
                <div className="p-1">
                    <div onDoubleClick={() => openModal(fieldName, title)} className="contentText p-3">{flowTriggerData[fieldName]}</div>
                </div>
            </div>
        </div>
    );
};

export default FlowTrigger;
