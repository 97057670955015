import React, { useEffect, useState } from 'react';
import '../../styles/PersonnalTraining.css';
import personnalTrainingImage from '../../img/personnal_training.png';
import DashBoardNavBar from "../DashboardNavbar";
import firebase from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";

const PersonnalTraining = ({user}) => {
    const [userName, setUserName] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Vérifier si l'utilisateur dispose d'un abonnement valide
        const checkPlanValidity = async () => {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    const data = docSnapshot.data();
                    const endSubscriptionDate = data.endSubscriptionDate.toDate();
                    setUserName(data.username);
                    if (endSubscriptionDate < new Date()) {
                        navigate('/');
                    }
                }
            }else{
                navigate('/dashboard')
            }
        };
        checkPlanValidity();
    }, []);

    return (
        <>
            <DashBoardNavBar userName={userName} boldItem={'personnalTraining'}/>
            <div className="container">
                <div className="personnal-training">
                    <div className="personnal-training-image">
                        <img src={personnalTrainingImage} alt="Personnal Training" style={{ width: '10em' }} />
                    </div>
                    <div className="personnal-training-content">
                        <h1>Professional Goals Coaching</h1>
                        <p>
                            Unlock your full potential in your professional life with our Personalized Goals Coaching. Our experienced coaches are dedicated to helping you define and achieve your career aspirations.
                        </p>
                        {/* Le reste du contenu ... */}
                        <button className="btn btn-primary">Get Started</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PersonnalTraining;
