import React, { useEffect, useRef, useState } from 'react';

const DayPicker = ({ returnedDate, onSave, isModified }) => {
    const [date, setDate] = useState(new Date());
    const dateInputRef = useRef(null);

    useEffect(() => {
        // Mettre à jour la date au chargement du composant
        setDate(new Date());
        returnedDate(date);
    }, []);

    useEffect(() => {
        // Gestion de l'événement "Ctrl+S" pour sauvegarder
        const handleSaveShortcut = (event) => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault(); // Empêcher l'action par défaut du navigateur
                if (isModified) {
                    onSave(); // Appeler la fonction de sauvegarde si le contenu est modifié
                }
            }
        };

        // Ajouter l'écouteur d'événement de clavier
        window.addEventListener('keydown', handleSaveShortcut);

        // Nettoyage de l'écouteur lors du démontage du composant
        return () => {
            window.removeEventListener('keydown', handleSaveShortcut);
        };
    }, [isModified, onSave]);

    const handleChange = (e) => {
        const selectedDate = new Date(e.target.value);
        const timezoneOffset = selectedDate.getTimezoneOffset();
        selectedDate.setMinutes(selectedDate.getMinutes() + timezoneOffset);
        setDate(selectedDate);
        returnedDate(selectedDate);
    };

    const getDayOfWeek = (date) => {
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return daysOfWeek[date.getDay()];
    };

    const getMonthAbbreviation = (date) => {
        const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return monthAbbreviations[date.getMonth()];
    };

    const formatNumber = (number) => number.toString().padStart(2, '0');

    const formattedDate = `${getDayOfWeek(date)} - ${formatNumber(date.getDate())} ${getMonthAbbreviation(date)} ${date.getFullYear()}`;
    const formatDate = (date) => {
        const formattedDate = new Date(date);
        const year = formattedDate.getFullYear();
        const month = (formattedDate.getMonth() + 1).toString().padStart(2, "0");
        const day = formattedDate.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    return (
        <div>
            <div className={"d-flex justify-content-between m-3"}>
                <input
                    type="date"
                    onChange={handleChange}
                    value={formatDate(date)}
                    ref={dateInputRef}
                    disabled={isModified}
                />
                <button className={"btn btn-primary btnSave pl-5 pr-5"} onClick={onSave} disabled={!isModified}>
                    Save
                </button>
                <h5>{formattedDate}</h5>
            </div>
        </div>
    );
};

export default DayPicker;
