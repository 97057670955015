import React from 'react';
import firebase from '../firebaseConfig';
import { useNavigate } from "react-router-dom";
const Welcome = ({ user }) => {
    const navigate = useNavigate();
    const accessDashboard = () => {
        const userId = user.uid;
        const userRef = firebase.firestore().collection('users').doc(userId);
        userRef
            .update({
                tutorialCompleted: true,
            })
            .then(() => {
                navigate('/dashboard')
            })
            .catch((error) => {
                console.error('Error updating tutorial completed status:', error);
            });
    };

    return (
        <div className="container">
            <h1 className="text-center mt-5 mb-3">Welcome on OFD!</h1>
            <p className="text-center">
                We are pleased to help you out achieving your dream goals.
                This section will introduce you to the basics and the foundamental of OFD
            </p>
            <div className="text-center">
                <button className="btn btn-primary" onClick={accessDashboard}>
                    Begin
                </button>
            </div>
        </div>
    );
};

export default Welcome;
