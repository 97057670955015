import React, { useEffect, useState } from 'react';
import { TiPencil } from "react-icons/ti";
import CustomModal from "../Dashboard_Tabs/CustomModal";
import firebase from "../../firebaseConfig";
import "../../styles/generalTabs.css"

const Cycles_Content = ({ user }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [displayContent, setDisplayContent] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [goalsData, setGoalsData] = useState({});
    const [currentWorkload, setCurrentWorkload] = useState(0);

    useEffect(() => {
        const fetchInputValue = async () => {
            try {
                if (user) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data().Goals_cycle;
                        if (data){
                            setGoalsData(data);
                        }else{
                            setGoalsData({
                                globalView_cycles_1:'',
                                globalView_cycles_2:'',
                                globalView_cycles_3:'',
                                globalView_cycles_4:'',
                                globalView_cycles_5:'',
                                secondary_cycles_1:'',
                                secondary_cycles_2:'',
                                secondary_cycles_3:'',
                                secondary_cycles_4:'',
                                secondary_cycles_5:'',
                                recovery_cycles_1:'',
                                recovery_cycles_2:'',
                                recovery_cycles_3:'',
                                recovery_cycles_4:'',
                                recovery_cycles_5:'',
                                weekly_schedule_cycle_1:'',
                                weekly_schedule_cycle_2:'',
                                weekly_schedule_cycle_3:'',
                                weekly_schedule_cycle_4:'',
                                weekly_schedule_cycle_5:'',
                                repeat_cycle_1:'',
                                repeat_cycle_2:'',
                                repeat_cycle_3:'',
                                repeat_cycle_4:'',
                                repeat_cycle_5:'',
                                easiest_cycle_1:'',
                                easiest_cycle_2:'',
                                easiest_cycle_3:'',
                                easiest_cycle_4:'',
                                easiest_cycle_5:'',

                                cycleType:'normal', // unused

                                cycleLength_0:'cycleLength_0',
                                cycleLength_1:'cycleLength_1',
                                cycleLength_2:'cycleLength_2',
                                cycleLength_3:'cycleLength_3',
                                startingPeriod_0:'startingPeriod_0',
                                startingPeriod_1:'startingPeriod_1',
                                startingPeriod_2:'startingPeriod_2',
                                startingPeriod_3:'startingPeriod_3',
                                endingPeriod_0:'endingPeriod_0',
                                endingPeriod_1:'endingPeriod_1',
                                endingPeriod_2:'endingPeriod_2',
                                endingPeriod_3:'endingPeriod_3',
                                deloading_0:'deloading_0',
                                deloading_1:'deloading_1',
                                deloading_2:'deloading_2',
                                deloading_3:'deloading_3',
                                periodRecovery_0:'periodRecovery_0',
                                periodRecovery_1:'periodRecovery_1',
                                periodRecovery_2:'periodRecovery_2',
                                periodRecovery_3:'periodRecovery_3',
                                nextCycle_0:'nextCycle_0',
                                nextCycle_1:'nextCycle_1',
                                nextCycle_2:'nextCycle_2',
                                nextCycle_3:'nextCycle_3',

                            })
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                // Gérer les erreurs de récupération des données
            }
        };
        fetchInputValue();
    }, [user]);

    const updateFieldValue = async (_fieldName,newValue) => {
        try {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);

                // Créer un objet avec le champ spécifié et sa nouvelle valeur
                const updatedData = {
                    ["Goals_cycle." + _fieldName]: newValue
                };

                // Mettre à jour l'état dynamiquement
                setGoalsData(prevState => ({
                    ...prevState,
                    [_fieldName]: newValue
                }));

                if (modalIsOpen) setModalIsOpen(false);
                await docRef.update(updatedData);
            }
        } catch (error) {
            console.log(error);
            // Gérer les erreurs de mise à jour des données
        }
    };
    const updateInputValue = async (newValue) => {
        try {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);

                // Créer un objet avec le champ spécifié et sa nouvelle valeur
                const updatedData = {
                    ["Goals_cycle." + fieldName]: newValue
                };

                // Mettre à jour l'état dynamiquement
                setGoalsData(prevState => ({
                    ...prevState,
                    [fieldName]: newValue
                }));

                if (modalIsOpen) setModalIsOpen(false);
                await docRef.update(updatedData);
            }
        } catch (error) {
            console.log(error);
            // Gérer les erreurs de mise à jour des données
        }
    };

    const openModal = (fieldName, title) => {
        setDisplayContent(goalsData[fieldName]);
        setFieldName(fieldName);
        setModalTitle(title);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    return (
        <div className="tab-pane containerDashboardContent container-fluid" role="tabpanel" id="tab-1">
            <h5 className={"mt-3"}>
            </h5>
            <div className="container" style={{backgroundColor:"rgba(238,238,238,0.56)", padding: "1em", borderRadius: "1em"}}>
                <div className="row">
                    <div className="col" style={{cursor:"pointer"}} onClick={() => openModal(`cycleLength_${currentWorkload}`, "Cycle Length (Weeks, Months)")}><h6>Cycle Length</h6> <div >{goalsData['cycleLength_' + currentWorkload]}</div></div>
                    <div className="col"><h6>Starting Period</h6>
                        <input className="bg-transparent border-0" style={{cursor:"pointer"}} type="date" value={goalsData[`startingPeriod_${currentWorkload}`]} onChange={(e) => updateFieldValue(`startingPeriod_${currentWorkload}`,e.target.value)}/>
                    </div>
                    <div className="col"><h6>Ending Period</h6>
                        <input className="bg-transparent border-0" style={{cursor:"pointer"}} type="date" value={goalsData[`endingPeriod_${currentWorkload}`]} onChange={(e) => updateFieldValue(`endingPeriod_${currentWorkload}`,e.target.value)}/>
                    </div>
                    <div className="col" style={{cursor:"pointer"}} onClick={() => openModal(`deloading_${currentWorkload}`, "Deloading")}><h6>Deloading</h6> <div >{goalsData['deloading_' + currentWorkload]}</div></div>
                    <div className="col" style={{cursor:"pointer"}} onClick={() => openModal(`periodRecovery_${currentWorkload}`, "Periods of Recovery")}><h6>Periods of Recovery</h6> <div >{goalsData['periodRecovery_' + currentWorkload]}</div></div>
                    <div className="col"><h>Next Cycle</h>
                        <input className="bg-transparent border-0" style={{cursor:"pointer"}} type="date" value={goalsData[`nextCycle_${currentWorkload}`]} onChange={(e) => updateFieldValue(`nextCycle_${currentWorkload}`,e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className="container mt-4">
                <div className="row">
                    <div className="col col-xs-12 col-sm-12 col-md-3 col-lg-3">
                        <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link active" id="v-pills-dream-tab" data-toggle="pill" href="#v-pills-dream" role="tab" aria-controls="v-pills-dream" aria-selected="true" onClick={() => setCurrentWorkload(0)}>NORMAL WORKLOAD</a>
                            <a className="nav-link" id="v-pills-end-tab" data-toggle="pill" href="#v-pills-end" role="tab" aria-controls="v-pills-end" aria-selected="false" onClick={() => setCurrentWorkload(1)}>OPTIMAL WORKLOAD</a>
                            <a className="nav-link" id="v-pills-performance-tab" data-toggle="pill" href="#v-pills-performance" role="tab" aria-controls="v-pills-performance" aria-selected="false" onClick={() => setCurrentWorkload(2)}>PEAK WORKLOAD</a>
                            <a className="nav-link" id="v-pills-process-tab" data-toggle="pill" href="#v-pills-process" role="tab" aria-controls="v-pills-process" aria-selected="false" onClick={() => setCurrentWorkload(3)}>HYPER WORKLOAD</a>
                        </div>
                    </div>
                    <div className="col col-xs-12 col-sm-12 col-md-9 col-lg-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-dream" role="tabpanel" aria-labelledby="v-pills-dream-tab">
                                <h4 style={{color: "#80be14"}}>Between 60 - 75% of Maximal Workload</h4>
                                <Card openModal={openModal} title={"Priorities (non-negotiable tasks/activities)"} fieldName={"globalView_cycles_1"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Secondary tasks/activities"} fieldName={"secondary_cycles_1"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Easiest tasks/activities"} fieldName={"easiest_cycle_1"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Periods of recovery, deloading & full rest"} fieldName={"recovery_cycles_1"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Weekly Cycle Schedule"} fieldName={"weekly_schedule_cycle_1"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"How many times a year can you repeat the cycle?"} fieldName={"repeat_cycle_1"} goalsData={goalsData} />
                            </div>
                            <div className="tab-pane fade" id="v-pills-end" role="tabpanel" aria-labelledby="v-pills-end-tab">
                                <h4 style={{color: "#ffe046"}}>Between 75 - 85% of Maximal Workfoad</h4>
                                <Card openModal={openModal} title={"Priorities (non-negotiable tasks/activities)"} fieldName={"globalView_cycles_2"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Secondary tasks/activities"} fieldName={"secondary_cycles_2"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Easiest tasks/activities"} fieldName={"easiest_cycle_2"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Periods of recovery, deloading & full rest"} fieldName={"recovery_cycles_2"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Weekly Cycle Schedule"} fieldName={"weekly_schedule_cycle_2"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"How many times a year can you repeat the cycle?"} fieldName={"repeat_cycle_2"} goalsData={goalsData} />
                            </div>
                            <div className="tab-pane fade" id="v-pills-performance" role="tabpanel" aria-labelledby="v-pills-performance-tab">
                                <h4 style={{color: "#ec7723"}}>Between 85 - 95% of Maximal Workload</h4>
                                <Card openModal={openModal} title={"Priorities (non-negotiable tasks/activities)"} fieldName={"globalView_cycles_3"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Secondary tasks/activities"} fieldName={"secondary_cycles_3"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Easiest tasks/activities"} fieldName={"easiest_cycle_3"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Periods of recovery, deloading & full rest"} fieldName={"recovery_cycles_3"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Weekly Cycle Schedule"} fieldName={"weekly_schedule_cycle_3"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"How many times a year can you repeat the cycle?"} fieldName={"repeat_cycle_3"} goalsData={goalsData} />
                            </div>
                            <div className="tab-pane fade" id="v-pills-process" role="tabpanel" aria-labelledby="v-pills-process-tab">
                                <h4 style={{color: "#b01500"}}>95% & beyond of Maximal Workload</h4>
                                <Card openModal={openModal} title={"Priorities (non-negotiable tasks/activities)"} fieldName={"globalView_cycles_4"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Secondary tasks/activities"} fieldName={"secondary_cycles_4"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Easiest tasks/activities"} fieldName={"easiest_cycle_4"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Periods of recovery, deloading & full rest"} fieldName={"recovery_cycles_4"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"Weekly Cycle Schedule"} fieldName={"weekly_schedule_cycle_4"} goalsData={goalsData} />
                                <Card openModal={openModal} title={"How many times a year can you repeat the cycle?"} fieldName={"repeat_cycle_4"} goalsData={goalsData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modalIsOpen && (
                <CustomModal
                    title={modalTitle}
                    closeModal={closeModal}
                    content={displayContent}
                    isOpen={modalIsOpen}
                    fieldName={fieldName}
                    saveField={updateInputValue}
                />
            )}
        </div>
    );
};

const Card = ({ openModal, title, fieldName, goalsData }) => {
    return (
        <div className="mb-4 fondGris">
            <p style={{ cursor: "pointer" }} onClick={() => openModal(fieldName, title)}>
                <span style={{ textDecoration: "underline" }}>{title}</span>
                <TiPencil style={{ marginLeft: "0.5em" }} />
            </p>
            <div onDoubleClick={() => openModal(fieldName, title)} className="contentText">
                {goalsData[fieldName]}
            </div>
        </div>
    );
};


export default Cycles_Content;
