import React, {useEffect, useState} from 'react';
import '../styles/Tutorials.css';
import DashBoardNavBar from "./DashboardNavbar";
import {useNavigate} from "react-router-dom";
import firebase from "../firebaseConfig";
import toast, { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css'; // choose your theme
import NoSubscription from "./NoSubscription";
toastConfig({ theme: 'dark' });

const Account = ({user}) => {
    const [userData, setUserData] = useState(false);
    const [planValidity, setPlanValidity] = useState(0);
    const navigate = useNavigate();
    const [eraseDataModal, setEraseDataModal] = useState(false)
    const [nightPerson, setNightPerson] = useState(false);

    useEffect(() => {
        // Vérifier si l'utilisateur dispose d'un abonnement valide

        const checkPlanValidity = async () => {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    const endSubscriptionDate = docSnapshot.data().endSubscriptionDate?.toDate();
                    const _nightPerson = docSnapshot.data().nightPerson;
                    setNightPerson(_nightPerson)
                    if (endSubscriptionDate > new Date()) {
                        setPlanValidity(2);
                        const tutorialCompleted = docSnapshot.data().tutorialCompleted;
                        setUserData(docSnapshot.data())
                        if(tutorialCompleted === false){
                            navigate('/welcome');
                        }
                    } else {
                        setPlanValidity(1);
                    }
                }
            }else {
                // Utilisateur non connecté, gérer le cas approprié ici si nécessaire
                // Par exemple, rediriger vers la page de connexion
                navigate('/login');
            }
        };
        checkPlanValidity();
    }, [user]);



    const formatDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            const date = new Date(timestamp.seconds * 1000);
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return date.toLocaleString('en-US', options);
        }
        return 'Date not available';
    };

    const handleForgotPassword = () => {
        firebase
            .auth()
            .sendPasswordResetEmail(userData.email)
            .then(() => {
                toast('Password reset sent. Please check your inbox.')
            })
    };

    const handleNightPersonChange = async () => {
        try {
            if (user) {
                const userId = firebase.auth().currentUser.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                await docRef.update({ nightPerson: !nightPerson });
            }
        } catch (error) {
            console.log("Error saving data:", error);
        }
        setNightPerson(!nightPerson);
    };
    const ResetConfirmationModal = () => {
        const resetData = async () => {
            try {
                const newData = {
                    Goals_1: {
                        experience_1: '',
                        globalView_1: '',
                        intentions_1: '',
                        in10x_1: '',
                        globalView_2: '',
                        intentions_2: '',
                        globalView_3: '',
                        intentions_3: '',
                        processGoals_4: '',
                        values_5: '',
                    },
                    SingleTasking_2: {
                        priorities_action_list: {
                            columns: [
                                {
                                    id: 1,
                                    title: 'TODO',
                                    cards: []
                                },
                                {
                                    id: 2,
                                    title: 'DOING',
                                    cards: []
                                },
                                {
                                    id: 3,
                                    title: 'DONE',
                                    cards: []
                                },
                            ]
                        },
                        secondary_action_list: {
                            columns: [
                                {
                                    id: 1,
                                    title: 'TODO',
                                    cards: []
                                },
                                {
                                    id: 2,
                                    title: 'DOING',
                                    cards: []
                                },
                                {
                                    id: 3,
                                    title: 'DONE',
                                    cards: []
                                },
                            ]
                        },
                        easiest_action_list: {
                            columns: [
                                {
                                    id: 1,
                                    title: 'TODO',
                                    cards: []
                                },
                                {
                                    id: 2,
                                    title: 'DOING',
                                    cards: []
                                },
                                {
                                    id: 3,
                                    title: 'DONE',
                                    cards: []
                                },
                            ]
                        },
                        clustering_action_list: {
                            columns: [
                                {
                                    id: 1,
                                    title: 'TODO',
                                    cards: []
                                },
                                {
                                    id: 2,
                                    title: 'DOING',
                                    cards: []
                                },
                                {
                                    id: 3,
                                    title: 'DONE',
                                    cards: []
                                },
                            ]
                        },
                        delegating_action_list: {
                            columns: [
                                {
                                    id: 1,
                                    title: 'TODO',
                                    cards: []
                                },
                                {
                                    id: 2,
                                    title: 'DOING',
                                    cards: []
                                },
                                {
                                    id: 3,
                                    title: 'DONE',
                                    cards: []
                                },
                            ]
                        },
                    },
                    FlowTrigger_3: {
                        curiosity_1: '',
                        concentration_2: '',
                        skill_3: '',
                        feedback_4: '',
                        richEnv_5: '',
                        deepEnv_6: '',
                        highEnv_7: '',
                        creativity_8: '',
                        triggers_9: ''
                    },
                    FlowRoutines_4: [{}, {}, {}, {}, {}],
                    Release_5: {
                        release_1: '',
                        release_2: '',
                        recoveryEnd_3: '',
                        restOff_4: '',
                        restVacation_5: ''
                    },
                    DistractionsInterruptions_6: [{}, {}, {}, {}, {}],
                    StickingPlan_7: [{}, {}, {}, {}, {}],
                    Logistics_8: [],
                    TimeManagement_9: [],
                    CausesSymptoms_10: [{}, {}, {}, {}, {}],
                    FlowTrackerData: []
                }

                // Mettez à jour les données dans la base de données Firebase
                await firebase.firestore().collection('users').doc(user.uid).update(newData);

                // Mettez à jour localement les données de l'utilisateur
                setEraseDataModal(false)
                window.location.reload(false);
            } catch (error) {
                setEraseDataModal(false);
                console.error('Error resetting user data:', error);
            }
        };

        return (
            <div className="modal fade show modal-overlay" style={{ display: "block" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            DATA FACTORY RESET
                            <button
                                className="btn-close"
                                style={{ cursor: "pointer" }}
                                type="button"
                                aria-label="Close"
                                onClick={() => setEraseDataModal(false)}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <h5>Are you sure to factory reset your personal data?</h5>
                                <div>This is gonna erase all of your personal data :</div>
                                <ul>
                                    <li>Flow state data</li>
                                    <li>Flow tracker data</li>
                                </ul>
                                <div className="d-flex justify-content-end">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={() => setEraseDataModal(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={resetData}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <>
            { planValidity === 2 &&
                <>
                <DashBoardNavBar userName={userData ? userData.username : ''} />
                <div className="container">
                    <h3 className="text-center mt-5 mb-3">My Account</h3>
                    <h5>User Id</h5>
                    <p>{user.uid}</p>
                    <h5>Username</h5>
                    <p>{userData ? userData.username : ''}</p>
                    <h5>Email</h5>
                    <p>{userData ? userData.email : ''}</p>
                    <h5>Password</h5>
                    <div className="d-flex align-items-center">
                        <div>***********</div>
                        <button type="button" className={"btn"} onClick={handleForgotPassword} style={{marginLeft:"1em", marginBottom:".5em"}}>Change</button>
                    </div>
                    <h5>Account created on</h5>
                    <p>{userData ? formatDate(userData.accountCreationDate) : ''}</p>
                    <h5>Your subscription will expire on</h5>
                    <p>{userData ? formatDate(userData.endSubscriptionDate) : ''}</p>
                    <h5>Night Person</h5>
                    <div className="form-check mb-4">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="nightPersonCheckbox"
                            checked={nightPerson}
                            onChange={handleNightPersonChange}
                        />
                        <label className="form-check-label" htmlFor="nightPersonCheckbox">Enable this parameter to account for after-night workflows will appear a new option in the end time calculation field on the flow tracker. <br/>
                            For example, selecting this option for a task spanning from "23:00" to "2:00" will correctly calculate the duration as 3 hours of workflow.</label>

                    </div>
                    <button className={"btn btn-danger"} onClick={() => setEraseDataModal(true)}>Reset Data</button>
                </div>
                {
                    eraseDataModal &&
                    <ResetConfirmationModal/>
                }
                </>
            }
            { planValidity === 1 &&
                <NoSubscription/>
            }
        </>
    );
};
export default Account;
