import React from "react";

const HomeNavbar = ({ user, boldItem }) => {
    return (
        <nav className="navbar navbar-light navbar-expand-lg bg-light navigation-clean">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src="img.png" alt="logoOFD" width={"50"}/>
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navcol-1"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navcol-1">
                    <ul className="navbar-nav ml-auto">
                        <li className={`nav-item ${boldItem === 'home' ? 'fw-bold' : ''}`}>
                            <a className="nav-link" href="/">
                                Home
                            </a>
                        </li>
                        <li className={`nav-item ${boldItem === 'pricing' ? 'fw-bold' : ''}`}>
                            <a className="nav-link" href="Pricing">
                                Pricing
                            </a>
                        </li>
                        <li className={`nav-item ${boldItem === 'contact' ? 'fw-bold' : ''}`}>
                            <a className="nav-link" href="/contact">
                                Contact
                            </a>
                        </li>
                        <li className={`nav-item ${boldItem === 'about' ? 'fw-bold' : ''}`}>
                            <a className="nav-link" href="About">
                                About
                            </a>
                        </li>
                    </ul>
                </div>
                {!user && (
                    <div>
                        <a
                            className="btn btn-primary ms-auto"
                            role="button"
                            href="Login"
                            style={{
                                background: 'transparent',
                                borderStyle: 'none',
                                color: 'var(--bs-navbar-brand-color)',
                            }}
                        >
                            Log in
                        </a>
                        <a
                            className="btn btn-primary ms-auto"
                            role="button"
                            href="Register"
                            style={{
                                background: 'transparent',
                                borderStyle: 'none',
                                color: 'var(--bs-navbar-brand-color)',
                            }}
                        >
                            Register
                        </a>
                    </div>
                )}
                {user && (
                    <a className="btn btn-primary" href="/dashboard">
                        Dashboard
                    </a>
                )}
            </div>
        </nav>
    );
};

export default HomeNavbar;
