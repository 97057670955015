import React, { useEffect, useState } from 'react';
import '../styles/Tutorials.css';
import DashBoardNavBar from "./DashboardNavbar";
import { useNavigate } from "react-router-dom";
import firebase from "../firebaseConfig";
import { toastConfig } from 'react-simple-toasts';
import 'react-simple-toasts/dist/theme/dark.css';
import Goals from "./Dashboard_Tabs/1_Goals";
import SingleTasking from "./Dashboard_Tabs/2_SingleTasking"; // choose your theme
toastConfig({ theme: 'dark' });


const Admin = ({ user }) => {
    const [userData, setUserData] = useState(null);
    const [users, setUsers] = useState([]);
    const [userToEdit, setUserToEdit] = useState(null);
    const [newSubscriptionDate, setNewSubscriptionDate] = useState('');
    const navigate = useNavigate();
    const [feedbacks, setFeedbacks] = useState([]);
    useEffect(() => {
        const fetchFeedbacks = async () => {
            try {
                const feedbacksSnapshot = await firebase.firestore().collection('feedbacks').get();
                const feedbacksData = feedbacksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setFeedbacks(feedbacksData);
            } catch (error) {
                console.error('Error fetching feedbacks:', error);
            }
        };
        fetchFeedbacks();
    }, []);
    useEffect(() => {
        // Vérifier si l'utilisateur dispose d'un abonnement valide
        const checkPlanValidity = async () => {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                        setUserData(docSnapshot.data());
                }
            } else {
                navigate('/login');
            }
        };
        checkPlanValidity();
    }, [user]);

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const usersSnapshot = await firebase.firestore().collection('users').get();
                const usersData = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsers(usersData);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        fetchAllUsers();
    }, [userData]);

    const formatDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            const date = new Date(timestamp.seconds * 1000);
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return date.toLocaleString('en-US', options);
        }
        return 'Date not available';
    };
    const formatEndDate = (timestamp) => {
        if (timestamp && timestamp.seconds) {
            const date = new Date(timestamp.seconds * 1000);
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            const formattedDate = date.toLocaleString('en-US', options);

            // Vérifier si la date est inférieure à aujourd'hui
            const currentDate = new Date();
            if (date < currentDate) {
                return <span style={{ color: 'red' }}>{formattedDate}</span>;
            }

            return formattedDate;
        }
        return 'Date not available';
    };


    const changeUserSubscription = async () => {
        try {
            if (userToEdit && newSubscriptionDate) {
                // Mettez à jour la date d'abonnement dans la base de données Firebase
                await firebase.firestore().collection('users').doc(userToEdit.id).update({
                    endSubscriptionDate: new Date(newSubscriptionDate),
                });

                // Mettez à jour localement la liste des utilisateurs
                setUsers(users.map(user => {
                    if (user.id === userToEdit.id) {
                        return { ...user, endSubscriptionDate: new Date(newSubscriptionDate) };
                    }
                    return user;
                }));

                // Réinitialisez l'état pour fermer la modal
                setUserToEdit(null);
                setNewSubscriptionDate('');
                window.location.reload(false);
            }
        } catch (error) {
            console.error('Error changing user subscription:', error);
        }
    };
    const EditSubscriptionModal = () => {
        return (
            <div className="modal fade show modal-overlay" style={{ display: "block" }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            Change user subscription
                            <button
                                className="btn-close"
                                style={{ cursor: "pointer" }}
                                type="button"
                                aria-label="Close"
                                onClick={() => setUserToEdit(null)}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <label htmlFor="newSubscriptionDate">New Subscription Date:</label>
                                <input
                                    type="date"
                                    id="newSubscriptionDate"
                                    value={newSubscriptionDate}
                                    onChange={(e) => setNewSubscriptionDate(e.target.value)}
                                />
                                <div className="d-flex justify-content-end mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={() => setUserToEdit(null)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={changeUserSubscription}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const deleteFeedback = async (feedbackId) => {
        try {
            // Supprimer le feedback de la collection Firestore
            await firebase.firestore().collection('feedbacks').doc(feedbackId).delete();

            // Mettre à jour localement la liste des feedbacks
            setFeedbacks(feedbacks.filter(feedback => feedback.id !== feedbackId));
        } catch (error) {
            console.error('Error deleting feedback:', error);
        }
    };

    return (
        <>
            {
                (users && users.length!==0) &&
                <>
                    <DashBoardNavBar userName={userData ? userData.username : ''} />
                    <div className="container">
                        <h3 className="text-center mt-5 mb-3">Admin Panel</h3>
                        <div>
                            <ul className="nav mainTabs" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" role="tab" data-bs-toggle="tab" href="#1-goals">
                                        USERS
                                    </a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" role="tab" data-bs-toggle="tab" href="#2-single">
                                        FEEDBACKS
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="1-goals" role="tabpanel">
                                    <ul>
                                        {users.map((user, index) => (
                                            <div key={index} className={"card m-2"}>
                                                <div className="card-header d-flex justify-content-between">
                                                    <h5>{user.email}</h5>
                                                    <div>userId: <i>{user.id}</i></div>
                                                </div>
                                                <div className={"p-2"}>
                                                    <b>Name:</b> {user.username}
                                                    <div className="d-flex">
                                                        <b>Main experience seeking</b>
                                                        <div>{user?.Goals_1?.experience_1}</div>
                                                    </div>
                                                    <div><b>Created</b> {formatDate(user.accountCreationDate)}</div>
                                                    <div>
                                                        <b>Expiring</b> {formatEndDate(user.endSubscriptionDate)}
                                                        <button className={"btn btn-sm btn-primary"} style={{marginLeft:"2em"}} onClick={function (){setUserToEdit(user)}}>Change</button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                                <div className="tab-pane fade" id="2-single" role="tabpanel">
                                    <ul>
                                        {feedbacks.map((feedback, index) => (
                                            <div key={index} className={"card m-2"}>
                                                <div className="card-header d-flex justify-content-between">
                                                    <h5>{feedback.title}</h5>
                                                    <button
                                                        className="btn btn-danger"
                                                        onClick={() => deleteFeedback(feedback.id)}
                                                    >
                                                        Delete Feedback
                                                    </button>
                                                </div>
                                                <div className={"p-2"}>
                                                    <div>
                                                        <b>Description:</b> {feedback.description}
                                                    </div>
                                                    <b>Username:</b> {feedback.userName} <u>({feedback.userId})</u>
                                                    <div>
                                                        <b>Timestamp:</b> {formatDate(feedback.timestamp)}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        userToEdit && <EditSubscriptionModal/>
                    }
                </>
            }
        </>
    );
};

export default Admin;