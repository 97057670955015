import React, { useState } from "react";

const SingleTaskingEditModal = ({ closeModal, onSave, onDelete, card }) => {
    const [title, setTitle] = useState(card.title);
    const [description, setDescription] = useState(card.description);

    const handleSave = () => {
        if (title.trim() === "") {
            alert("Title is required.");
        } else {
            const savedTask = {
                title,
                description,
            };
            onSave(savedTask);
            closeModal();
        }
    };
    const handleDelete = () => {
        onDelete();
        closeModal();
    };
    return (
        <div className="modal fade show modal-overlay" style={{ display: "block" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Edit a task</h4>
                        <button className="btn-close" type="button" aria-label="Close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="taskTitle" className="form-label">Task Title *</label>
                            <input
                                type="text"
                                className="form-control"
                                id="taskTitle"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="taskDescription" className="form-label">Task description</label>
                            <textarea
                                className="form-control"
                                id="taskDescription"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                rows={4}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-light" type="button" onClick={closeModal}>
                            Close
                        </button>
                        <button className="btn btn-danger" type="button" onClick={handleDelete}>
                            Delete
                        </button>
                        <button className="btn btn-primary" type="button" onClick={handleSave}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleTaskingEditModal;
