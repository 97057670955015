import React, { useState, useEffect } from "react";

const CustomModal = ({ fieldName, title, content, closeModal, saveField }) => {
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        setInputValue(content);
    }, [content]);

    return (
        <div className="modal fade show modal-overlay" style={{ display: "block" }}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{title}</h4>
                        <button className="btn-close" type="button" aria-label="Close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                        <textarea
                            className="form-control w-100"
                            style={{ resize: "none", minHeight: "10em" }}
                            value={inputValue}
                            onChange={handleInputChange}
                            rows={Math.max(3, inputValue?.split("\n").length)}
                            placeholder={fieldName === "globalView_1" && content === "" ? "I want to" :
                                fieldName === "intentions_1" && content === "" ? "I will" :
                                fieldName === "globalView_2" && content === "" ? "For the next 3 to 5 years I want to engage in/deliver" :
                                fieldName === "intentions_2" && content === "" ? "I will" :
                                fieldName === "globalView_3" && content === "" ? "This year, I want to" :
                                fieldName === "intentions_3" && content === "" ? "I intend to" :
                                fieldName === "processGoals_4" && content === "" ? "I intend to" :
                                ''
                            }
                        ></textarea>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-light" type="button" onClick={closeModal}>Close</button>
                        <button className="btn btn-primary" type="button" onClick={() => saveField(inputValue)}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomModal;
