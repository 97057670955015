import React from 'react';
import HomeNavbar from "./HomeNavbar";

const AboutUs = ({user}) => {
    return (
        <>
            <HomeNavbar user={user} boldItem={"about"}/>
            <section className="container mt-5">
                <div className="row">
                    <div>
                        <h5 className="mb-4">About Us: Empowering Your Productivity</h5>
                        <p className={"bg-light"}>
                            At <strong>OFD</strong>, we believe that everyone has
                            the potential to achieve their goals and dreams, but the modern
                            world can be overwhelming, filled with distractions and constant
                            demands on our time. Our vision is simple yet powerful: we are here
                            to help individuals regain control of their lives and supercharge
                            their productivity.
                        </p>
                        <h5 className="mt-4">Why Productivity Matters</h5>
                        <p>
                            In today's fast-paced society, being productive is more than just a
                            buzzword; it's the key to unlocking your full potential.
                            Productivity isn't about working harder; it's about working smarter.
                            It's about finding the balance between achieving your goals and
                            having the time to enjoy life's moments.
                        </p>
                        <h5 className="mt-4">Our Mission</h5>
                        <p>
                            Our mission is to empower individuals like you to take charge of
                            your time and energy. We provide innovative tools, resources, and
                            guidance to help you regain control of your day, focus on what truly
                            matters, and achieve your objectives.
                        </p>
                        <h5 className="mt-4">What Sets Us Apart</h5>
                        <ul>
                            <li>
                                <strong>Tailored Solutions:</strong> We understand that one size
                                does not fit all. Our solutions are designed to be adaptable to
                                your unique needs and goals.
                            </li>
                            <li>
                                <strong>Expert Guidance:</strong> Our team of experts is
                                dedicated to providing you with the knowledge and support you
                                need to succeed. From time management strategies to goal setting,
                                we're here for you every step of the way.
                            </li>
                            <li>
                                <strong>User-Friendly Technology:</strong> We leverage
                                cutting-edge technology to make productivity accessible to
                                everyone. Our user-friendly apps and platforms are designed to
                                simplify your life, not complicate it.
                            </li>
                            <li>
                                <strong>Community:</strong> Join a community of like-minded
                                individuals who are also on the journey to regain their
                                productivity. Share insights, ask questions, and find inspiration
                                from others who have walked the same path.
                            </li>
                        </ul>
                        <h5 className="mt-4">Join Us in this Journey</h5>
                        <p>
                            We invite you to join us on this journey to reclaim your time, boost
                            your productivity, and achieve your aspirations. Whether you're a
                            busy professional, a student with a heavy workload, or anyone
                            looking to make the most of their day, we're here to support you.
                        </p>
                        <p>
                            At <strong>OFD</strong>, we don't just offer solutions;
                            we offer a transformational experience. It's time to take back
                            control of your life and start living it to the fullest. Together,
                            we can turn your dreams into reality.
                        </p>
                        <p>
                            Thank you for choosing us as your partner in productivity. We're
                            excited to be part of your success story.
                        </p>
                    </div>
                </div>
            </section>
            {/* Section about Cookies and Data Privacy */}
            <section className="bg-light py-3">
                <div className="container">
                    <div className="row">
                        <div className="">
                            <h5>Cookies and Data Privacy</h5>
                            <p>
                                At <strong>OFD</strong>, we take your privacy seriously. This website uses cookies to enhance your browsing experience and provide personalized content.
                            </p>
                            <p>
                                Rest assured, your data is treated with the utmost confidentiality. We do not share your personal information with third parties without your explicit consent. For more details on how we handle your data, please review our Privacy Policy and Cookie Policy.
                            </p>
                            <p>
                                If you have any questions or concerns about our privacy practices, please contact us at email waoist@yahoo.com.
                            </p>
                            <h5>Refunds</h5>
                            <p>Please contact jason@gmail.com to ask for a refund. Refund requests are evaluated individually.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;
