import React, {useEffect, useState} from 'react';
import backgroundImage from '../img/waveBG.jpg';
import "../styles/HomePage.css"
import {BsGraphUp, BsPieChart, BsPersonBadge} from "react-icons/bs";
import testimonials1 from '../img/testimonials-1.jpg';
import testimonials2 from '../img/testimonials-2.jpg';
import testimonials3 from '../img/testimonials-3.jpg';
import screen1 from '../img/dailyRoutine.png';
import screen2 from '../img/FlowCHarts2.png';
import screen3 from '../img/GoalsImg.png';
import screen4 from '../img/TimeManagmentImg.png';
import phone from '../img/OFD Mobile.png';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Alert } from 'react-bootstrap';
import HomeNavbar from "./HomeNavbar";

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(true);

    useEffect(() => {
        // Vérifie si l'utilisateur a déjà accepté les cookies
        const cookieAccepted = localStorage.getItem('cookieAccepted');
        if (cookieAccepted) {
            setShowBanner(false);
        }
    }, []);

    const acceptCookies = () => {
        // Stocke l'acceptation des cookies dans le stockage local
        localStorage.setItem('cookieAccepted', 'true');
        setShowBanner(false);
    };
    const denyCookies = () => {
        localStorage.setItem('cookieAccepted', 'false');
        setShowBanner(false);
    };

    return (
        showBanner && (
            <Alert variant="primary" className="cookie-banner fixed-bottom" style={{position:"sticky", top:"2em", borderRadius:"0px"}}>
                <div className="d-flex justify-content-center align-items-center">
                    <p className="mb-0">
                        This website uses cookies to enhance the user experience.{' '}
                        <a href="/about" className="alert-link">
                            Learn more
                        </a>
                    </p>
                    <button onClick={acceptCookies} className="btn" style={{border:"1px solid black", marginLeft:"1em"}}>
                        Accept
                    </button>
                    <button onClick={denyCookies} className="btn" style={{ marginLeft:"1em"}}>
                        x
                    </button>
                </div>
            </Alert>
        )
    );
};

function HomePage({user}) {
    return (
        <>
            <HomeNavbar user={user} boldItem={"home"}/>
            <header className="text-center text-white masthead" style={{height:'50em', backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "no-repeat", backgroundPosition: "center center", backgroundSize: "cover"}}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-9 mx-auto position-relative">
                            <h1 className="mb-5" style={{textShadow: '#888888 0em 0em 0.4em'}}>MANAGE YOUR WORKFLOW AND MOTIVATION WITH OFD AND LET YOUR DREAMS BECOME REALITY</h1>
                        </div>
                    </div>
                </div>
            </header>
            <section className="text-center bg-light features-icons p-5">
                <div className="container d-xxl-flex justify-content-xxl-center mt-5 flex-column" >
                    <h2>WHAT IS OFD?</h2>
                    <p className={"lead"}>OFD, which stands for "Optimal Flow Design" is more than just a personal training coach; it's your dedicated partner in professional growth. OFD is a dynamic platform designed to help individuals like you achieve their goals and transform their careers. Whether you're striving for career advancement, enhanced productivity, or a better work-life balance, OFD empowers you with the tools and guidance to make it happen. With personalized coaching, goal-setting, and performance tracking, OFD is your compass on the path to success.</p>
                </div>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="mx-auto features-icons-item mb-4 mb-lg-0 mb-lg-3 pb-4">
                                <div className={"d-flex features-icons-icon p-3"}> <BsPersonBadge className={"icon-organization m-auto text-primary menuIcon"}/> </div>
                                <h4>Your Personal Training Companion</h4>
                                <p className="lead mb-0">Meet your dedicated personal trainer, ready to guide you towards your goals. Whether you're aiming for career advancement, improved productivity, or a balanced work-life routine, our platform is designed to empower you.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3 pb-4">
                                <div className={"d-flex features-icons-icon p-3"}> <BsGraphUp className={"icon-organization m-auto text-primary menuIcon"}/> </div>
                                <h4>Boost Your Performance</h4>
                                <p className="lead mb-0">Experience a significant improvement in your professional performance with OFD. Our platform leverages the latest tools and resources to help you enhance your productivity, achieve your goals, and excel in your career. Say goodbye to mediocrity and hello to peak performance.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="mx-auto features-icons-item mb-5 mb-lg-0 mb-lg-3 pb-4">
                                <div className={"d-flex features-icons-icon p-3"}> <BsPieChart className={"icon-organization m-auto text-primary menuIcon"}/> </div>
                                <h4>Track Real-Time Progression</h4>
                                <p className="lead mb-0">Stay in control of your journey with real-time progress tracking. Whether you're using our default content or customizing your own, OFD provides the insights you need to make informed decisions and achieve your dreams.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="index-howto-alt py-3">
                <div className="container">
                    <h2 className="text-center mt-5">WHY CHOOSE OFD?</h2>
                    <p className="text-center lead">In today's fast-paced professional world, avoiding burnout is crucial for long-term success. At OFD, we understand the challenges you face in maintaining a healthy work-life balance while pursuing your career ambitions. Our platform not only helps you set and manage your goals but also emphasizes the significance of self-care and well-being. We provide resources and strategies to help you prevent professional burnout, ensuring you stay energized, motivated, and on track to achieve your dreams.</p>
                    <div className="psd-line pos-rel" >
                        <div className="row align-items-center">
                            <div
                                className="col-12 col-sm-6 d-flex flex-row justify-content-center align-items-center intro-card-1 p-5">
                                <img src={screen3} alt={"screen3"} className={"w-100"}/>
                            </div>
                            <div className="col-12 col-sm-6 d-flex flex-column justify-content-center p-5 psd-left1">
                                <h2>Define your life goals and objectives</h2>
                                <p className={"lead"}>Empower yourself to set clear and meaningful life goals. Our platform OFD helps you define your objectives and provides the guidance you need to achieve them. Whether it's personal or professional, we're here to support your journey. All with simplicity.</p>
                            </div>
                        </div>
                        <div className="row flex-column-reverse align-items-center flex-sm-row">
                            <div className="col-12 col-sm-6 d-flex flex-column justify-content-center p-5 psd-right1">
                                <h2 className={"text-end"}>Manage your schedule</h2>
                                <p className="lead m-0 text-center text-sm-end">Efficiently organize your time and commitments. OFD gives you the tools to take control of your schedule, ensuring you make the most of every day. Say goodbye to chaos and hello to productivity.</p>
                            </div>
                            <div className="col-12 col-sm-6 d-flex flex-row justify-content-center align-items-center intro-card-2 p-5">
                                <img src={screen4} alt={"screen4"} className={"w-100"}/>
                            </div>
                        </div>
                    </div>
                    <div className="psd-line pos-rel" >
                        <div className="row align-items-center">
                            <div
                                className="col-12 col-sm-6 d-flex flex-row justify-content-center align-items-center intro-card-1 p-5">
                                <img src={screen1} alt={"screen1"} className={"w-100"}/>
                            </div>
                            <div className="col-12 col-sm-6 d-flex flex-column justify-content-center p-5 psd-left1">
                                <h2>Register your performance</h2>
                                <p className={"lead"}>Track your progress and accomplishments.  ODF allows you to register your performance and milestones. See how far you've come and celebrate your achievements along the way.</p>
                            </div>
                        </div>
                        <div className="row flex-column-reverse align-items-center flex-sm-row">
                            <div className="col-12 col-sm-6 d-flex flex-column justify-content-center p-5 psd-right1">
                                <h2 className={"text-end"}>And visualize the evolution of your intense work</h2>
                                <p className="lead m-0 text-center text-sm-end">Gain insight into your journey. With OFD, you will be able to visualize the evolution of your hard work and dedication. See the progress you've made, identify areas for improvement, and stay motivated to reach your goals.</p>
                            </div>
                            <div className="col-12 col-sm-6 d-flex flex-row justify-content-center align-items-center intro-card-2 p-5">
                                <img src={screen2} alt={"screen2"} className={"w-100"}/>
                            </div>
                        </div>
                    </div>
                    <div className="row flex-column-reverse align-items-center flex-sm-row mt-5">
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-6 d-flex flex-row justify-content-center align-items-center intro-card-1 p-5">
                                <img src={phone} alt={"phone"} style={{width:"15em"}}/>
                            </div>
                            <div className="col-12 col-sm-6 d-flex flex-column justify-content-center p-5 psd-left1">
                                <h2 className={"text-end"}>The perfect companion</h2>
                                <p className="lead m-0 text-center text-sm-end">Always with you, we developed an app to assist you at any moment of your workflows.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-center bg-light testimonials">
                <div className="container">
                    <h2 className={"mt-5 pt-4"}>What people are saying...</h2>
                    <div className="row">
                        <div className="col-lg-4 mt-5 mb-5">
                            <div className="mx-auto testimonial-item mb-5 mb-lg-0">
                                <img className="rounded-circle img-fluid mb-3 w-25" src={testimonials1} alt="Margaret E." />
                                <h5>Margaret E.</h5>
                                <p className="font-weight-light mb-0">This is fantastic! The coach is truly professional and very attentive. I highly recommend it."</p>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-5 mb-5">
                            <div className="mx-auto testimonial-item mb-5 mb-lg-0">
                                <img className="rounded-circle img-fluid mb-3 w-25" src={testimonials2} alt="Fred S." />
                                <h5>Fred S.</h5>
                                <p className="font-weight-light mb-0">"OFD is amazing! I've been using it for a year now, and it has helped me reorganize all my days so I can finally find free time."</p>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-5 mb-5">
                            <div className="mx-auto testimonial-item mb-5 mb-lg-0">
                                <img className="rounded-circle img-fluid mb-3 w-25" src={testimonials3} alt="Sarah W." />
                                <h5>Sarah W.</h5>
                                <p className="font-weight-light mb-0">"Simply the best scheduling platform, easy to use, and a life-changer."</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br className={"mt-5"}/>
            <footer className="footer mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-center text-lg-start my-auto h-100">
                            <ul className="list-inline mb-2">
                                <li className="list-inline-item"><a href="/about">About</a></li>
                                <li className="list-inline-item"><span>⋅</span></li>
                                <li className="list-inline-item"><a href="/contact">Contact</a></li>
                                <li className="list-inline-item"><span>⋅</span></li>
                                <li className="list-inline-item"><a href="/TOS.html">Terms of Use</a></li>
                            </ul>
                            <p className="text-muted small mb-4 pb-5 mb-lg-0">© OFD 2023. All Rights Reserved.</p>
                        </div>
                        <div className="col-lg-6 text-center text-lg-end my-auto h-100"></div>
                    </div>
                </div>
            </footer>
            <CookieBanner />
        </>
    );
}

export default HomePage;
