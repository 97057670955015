import React, { useState } from 'react';
import firebase from '../firebaseConfig';
import '../styles/Login.css';

function LoginForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [formValid, setFormValid] = useState(false);

    const handleEmailLogin = async (e) => {
        e.preventDefault();

        if (!formValid) {
            setError('Please fill in all the required fields.');
            return;
        }

        try {
            const { user } = await firebase.auth().signInWithEmailAndPassword(email, password);

            if (user && !user.emailVerified) {
                setError('Please verify your email before logging in.');
                return;
            }else {
                window.location.href = "/dashboard";
            }
        } catch (error) {
            console.log('Error logging in:', error);
            if (
                error.message === "Firebase: The password is invalid or the user does not have a password. (auth/wrong-password)." ||
                error.message === "Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)." ||
                error.message === "FirebaseError: Firebase: The email address is badly formatted. (auth/invalid-email)." ||
                error.message === "FirebaseError: Firebase: The email address is badly formatted. (auth/invalid-email)." ||
                error.message === "Error logging in: FirebaseError: Firebase: There is no user record corresponding to this identifier. The user may have been deleted. (auth/user-not-found)."
            ) {
                setError("Invalid Credentials");
            }
            if(error.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."){
                setError("Access to this account has been temporarily disabled due to many failed login attempts. Please try later.");
            }
        }
    };


    const handleForgotPassword = () => {
        const emailAddress = email; // Utilisez l'email saisi par l'utilisateur

        firebase
            .auth()
            .sendPasswordResetEmail(emailAddress)
            .then(() => {
                setError('Password reset sent if email exists. Please check your inbox.');
            })
            .catch((error) => {
                setError('Invalid or no email entered');
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }

        // Vérifier si les champs sont remplis
        setFormValid(email !== '' && password !== '' || (email !== '' && password === '') || (email === '' && password !== ''));
    };

    return (
        <div className="container centering login">
            <div className="vertical-center">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title text-center">Login</h5>
                        <div className="mt-5"></div>
                        <form onSubmit={handleEmailLogin} name="login_form" className={"d-flex align-items-center flex-column"}>
                            {error && (
                                <div className="alert alert-danger w-100" role="alert">
                                    {error}
                                </div>
                            )}
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="form-control"
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary mt-3">Login</button>
                            <div className="mt-1">
                                <button type="button" className="btn btn-link" onClick={handleForgotPassword}>
                                    Forgot Password?
                                </button>
                            </div>
                            <div className="mt-1">
                                <a href="/register" className={"text-decoration-none"} style={{color:"grey"}}>Create an account</a>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginForm;
