import React from 'react';
import HomeNavbar from "./HomeNavbar";

const Pricing = ({user}) => {
    return (
        <>
            <HomeNavbar user={user} boldItem={"pricing"}/>
            <h1 className="text-center mt-3">Pricing Plans</h1>
            <p className="text-center">Choose the perfect plan for your needs. All plans come with a 30-day money-back guarantee.</p>
            <div className="vertical-center">
                <div className={"d-flex "}>
                    <div className="card h-100 m-2">
                        <div className="card-body flex-grow-0 p-4">
                            <span className="badge bg-primary text-uppercase mb-2">1 Month</span>
                            <h4 className="display-4 fw-bold card-title">$20<span
                                className="fs-3 fw-normal text-muted">/mo +tx</span></h4>
                        </div>
                        <div className="card-footer d-flex flex-column flex-grow-1 justify-content-between p-4">
                            <div>
                                <ul className="list-unstyled">
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Lectus ut nibh quam, felis porttitor.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Ante nec venenatis etiam lacinia.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Porta suscipit netus ad ac.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Morbi praesent aptent integer at.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Nisl potenti ut auctor lobortis.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="card h-100 m-2">
                        <div className="card-body flex-grow-0 p-4">
                            <span className="badge bg-primary text-uppercase mb-2">3 Month</span>
                            <h4 className="display-4 fw-bold card-title">$16<span
                                className="fs-3 fw-normal text-muted">/mo +tx</span></h4>
                        </div>
                        <div className="card-footer d-flex flex-column flex-grow-1 justify-content-between p-4">
                            <div>
                                <ul className="list-unstyled">
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Lectus ut nibh quam, felis porttitor.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Ante nec venenatis etiam lacinia.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Porta suscipit netus ad ac.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Morbi praesent aptent integer at.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Nisl potenti ut auctor lobortis.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="card h-100 m-2">
                        <div className="card-body flex-grow-0 p-4">
                            <span className="badge bg-primary text-uppercase mb-2">1 Year</span>
                            <h4 className="display-4 fw-bold card-title">$12<span
                                className="fs-3 fw-normal text-muted">/mo +tx</span></h4>
                        </div>
                        <div className="card-footer d-flex flex-column flex-grow-1 justify-content-between p-4">
                            <div>
                                <ul className="list-unstyled">
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Lectus ut nibh quam, felis porttitor.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Ante nec venenatis etiam lacinia.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Porta suscipit netus ad ac.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Morbi praesent aptent integer at.</span>
                                    </li>
                                    <li className="d-flex mb-2">
                                        <span className="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"></span>
                                        <span>Nisl potenti ut auctor lobortis.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="container mt-5 w-75">
                        <h5>Experience Our Services with a 15-Day Free Trial.</h5>
                        <p>At OFD, we understand that trying out a new service is a significant decision. That's why we're excited to offer all new users a 15-day free trial of our premium services. It's our way of giving you the opportunity to explore everything we have to offer, completely risk-free.</p>
                    </div>
            </div>
        </>
    );
};

export default Pricing;
