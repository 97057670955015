import React, { useState } from 'react';
import HomeNavbar from "./HomeNavbar";

const Contact = ({user}) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleMailtoLinkClick = () => {
        const subject = "Inquiry";
        const mailtoLink = `mailto:ioansavoiupro@gmail.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
        window.location.href = mailtoLink;
    };

    return (
        <>
            <HomeNavbar user={user} boldItem={"contact"}/>
            <div className="container mt-4">
                <h1>Contact Us</h1>
                <form>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Your Email address</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="message" className="form-label">Message</label>
                        <textarea
                            className="form-control"
                            id="message"
                            rows="4"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <button type="button" className="btn btn-primary" onClick={handleMailtoLinkClick}>
                        Send
                    </button>
                </form>
            </div>
        </>
    );
};

export default Contact;
