const NoSubscription = () => {
    return (
        <div className="container d-flex align-items-center justify-content-center mt-5">
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">No subscription</h5>
                    <p>Please choose a new plan in order to continue using OFD, you can try it for free.
                        <br/>
                        If you think this is a problem, please <a href="/contact">contact</a> our support.</p>
                    <a href="/subscription" className="btn btn-primary">Go to Subscription Plans</a>
                </div>
            </div>
        </div>
    )
}
export default NoSubscription