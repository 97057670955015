import React from "react";

const EmailSent = () => {
    return <div className="container d-flex align-items-center justify-content-center mt-5">
        <div className="card">
            <div className="card-body d-flex flex-column align-items-center">
                <p>A confirmation mail has been sent, please check your mailbox.</p>
                <a href="/login">Login</a>
            </div>
        </div>
    </div>
}

export default EmailSent;