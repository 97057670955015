import React, { useState } from 'react';
import firebase from '../firebaseConfig';
import '../styles/Login.css';
import emailjs from '@emailjs/browser';

const sendEmail = (email) => {
    emailjs
        .send('service_sog1ju4', 'template_oc68bva',{
            new_email:email,
        }, "4jjERzKNDrrMYUMgm")
        .then(
            () => {
                console.log('SUCCESS!');
            },
            (error) => {
                console.log('FAILED...', error.text);
            },
        );
};

function Register() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const isValidEmail = (email) => {
        // Expression régulière pour vérifier le format d'une adresse e-mail
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = () => {
        if (email === '' || !isValidEmail(email)) {
            setError('Email must be valid');
            return false;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return false;
        }
        return true;
    };

    const handleRegistration = async (e) => {
        e.preventDefault();

        if (validatePassword()) {
            try {
                // Créer un nouvel utilisateur avec email et mot de passe
                const { user } = await firebase.auth().createUserWithEmailAndPassword(email, password);

                // Enregistrer les données utilisateur dans Firestore
                const docRef = firebase.firestore().collection('users').doc(user.uid);
                await docRef.set({
                    Goals_1: {
                        experience_1: '',
                        globalView_1: '',
                        intentions_1: '',
                        in10x_1: '',
                        globalView_2: '',
                        intentions_2: '',
                        globalView_3: '',
                        intentions_3: '',
                        processGoals_4: '',
                        values_5: '',
                    },
                    SingleTasking_2: {
                        priorities_action_list: {
                            columns: [
                                {
                                    id: 1,
                                    title: 'TODO',
                                    cards: []
                                },
                                {
                                    id: 2,
                                    title: 'DOING',
                                    cards: []
                                },
                                {
                                    id: 3,
                                    title: 'DONE',
                                    cards: []
                                },
                            ]
                        },
                        secondary_action_list: {
                            columns: [
                                {
                                    id: 1,
                                    title: 'TODO',
                                    cards: []
                                },
                                {
                                    id: 2,
                                    title: 'DOING',
                                    cards: []
                                },
                                {
                                    id: 3,
                                    title: 'DONE',
                                    cards: []
                                },
                            ]
                        },
                        easiest_action_list: {
                            columns: [
                                {
                                    id: 1,
                                    title: 'TODO',
                                    cards: []
                                },
                                {
                                    id: 2,
                                    title: 'DOING',
                                    cards: []
                                },
                                {
                                    id: 3,
                                    title: 'DONE',
                                    cards: []
                                },
                            ]
                        },
                        clustering_action_list: {
                            columns: [
                                {
                                    id: 1,
                                    title: 'TODO',
                                    cards: []
                                },
                                {
                                    id: 2,
                                    title: 'DOING',
                                    cards: []
                                },
                                {
                                    id: 3,
                                    title: 'DONE',
                                    cards: []
                                },
                            ]
                        },
                        delegating_action_list: {
                            columns: [
                                {
                                    id: 1,
                                    title: 'TODO',
                                    cards: []
                                },
                                {
                                    id: 2,
                                    title: 'DOING',
                                    cards: []
                                },
                                {
                                    id: 3,
                                    title: 'DONE',
                                    cards: []
                                },
                            ]
                        },
                    },
                    FlowTrigger_3: {
                        curiosity_1 : '',
                        concentration_2 : '',
                        skill_3 : '',
                        feedback_4 : '',
                        richEnv_5 : '',
                        deepEnv_6 : '',
                        highEnv_7 : '',
                        creativity_8 : '',
                        triggers_9 : ''
                    },
                    FlowRoutines_4: [{},{},{},{},{}],
                    Release_5: {
                        release_1: '',
                        release_2: '',
                        recoveryEnd_3: '',
                        restOff_4: '',
                        restVacation_5: ''
                    },
                    DistractionsInterruptions_6: [{},{},{},{},{}],
                    StickingPlan_7: [{},{},{},{},{}],
                    Logistics_8: [],
                    TimeManagement_9: [],
                    CausesSymptoms_10: [{},{},{},{},{}],
                    FlowTrackerData: [],
                    username,
                    email,
                    accountCreationDate: new Date(),
                    endSubscriptionDate: new Date(),
                    tutorialCompleted: false,
                    freePeriodUsed: false
                })
                .then(console.log("Data Initialized"))
                .catch((error) => {
                    console.log('Error creating user:', error);
                    setError('Error creating user.');
                });

                // Envoyer l'e-mail de vérification
                await user.sendEmailVerification();

                sendEmail(email)

                // Rediriger l'utilisateur vers une page de confirmation ou une autre destination
                window.location.href = '/emailSent';
            } catch (error) {
                console.log('Error creating user:', error);
                setError(error.message);
            }
        }
    };

    return (
        <div className="container centering login">
            <div className="vertical-center">
                <div className="card">
                    <div className="card-body ">
                        <h5 className="card-title text-center">Create a new account</h5>
                        <div className="mt-5"></div>
                        <form onSubmit={handleRegistration} name="registration_form" className={"registration_form"}>
                            {error && (
                                <div className="alert alert-danger notifFormRegister" role="alert">
                                    {error}
                                </div>
                            )}
                            <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    className="form-control"
                                    placeholder="Enter your username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    className="form-control"
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="confirmPassword">Confirm password</label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    className="form-control"
                                    placeholder="Confirm your password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary mt-4">Create</button>
                            <div className="mt-3">
                                <a href="/login">Already have an account?</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
