import firebase from '../firebaseConfig';
import "../styles/dashboard.css"
import {useEffect, useState} from "react";
import DashBoardNavBar from "./DashboardNavbar";
import { useNavigate } from "react-router-dom";
import NoSubscription from "./NoSubscription";
import Cycles_Content from "./Cycles_Tabs/1_Cycles";

function Cycles({ user }) {
    const [planValidity, setPlanValidity] = useState(0);
    const [userName, setUserName] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        // Vérifier si l'utilisateur dispose d'un abonnement valide
        const checkPlanValidity = async () => {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                const docSnapshot = await docRef.get();
                if (docSnapshot.exists) {
                    const data = docSnapshot.data();
                    const endSubscriptionDate = data.endSubscriptionDate.toDate();
                    setUserName(data.username)
                    if (endSubscriptionDate > new Date()) {
                        setPlanValidity(2);
                        const tutorialCompleted = data.tutorialCompleted;
                        if(tutorialCompleted === false){
                            navigate('/welcome');
                        }
                    } else {
                        setPlanValidity(1);
                    }
                }
            } else {
                // Utilisateur non connecté, gérer le cas approprié ici si nécessaire
                // Par exemple, rediriger vers la page de connexion
                navigate('/login');
            }
        };
        checkPlanValidity();
    }, [user]);

    return (
        <>
            { planValidity === 2 &&
                <>
                    <DashBoardNavBar userName={userName} boldItem={'cycles'}/>
                    <section className="position-relative pt-2">
                        <div className="container mainContainer">
                            <Cycles_Content user={user}/>
                        </div>
                    </section>
                </>
            }
            { planValidity === 1 &&
                <NoSubscription/>
            }
            <div className="mt-5"></div>
        </>
    );
}

export default Cycles;