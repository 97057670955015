import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import firebase from "../../firebaseConfig";
import "../../styles/generalTabs.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {TiDelete, TiPlus} from "react-icons/ti";

const DistractionsInterruptions = ({ user }) => {
    const [distractionsInterruptionsData, setDistractionsInterruptionsData] = useState([{},{},{},{},{}]);

    useEffect(() => {
        const fetchInputValue = async () => {
            try {
                if (user) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data().DistractionsInterruptions_6;
                        setDistractionsInterruptionsData(data);
                    }
                }
            } catch (error) {
                console.log(error);
                // Gérer les erreurs de récupération des données
            }
        };
        fetchInputValue();
    }, [user]);

    const updateDistractionsInterruptionsData = (index, field, value) => {
        setDistractionsInterruptionsData((prevData) => {
            const updatedData = [...prevData];
            const fieldParts = field.split('.');
            const nestedField = fieldParts.pop();
            let nestedObject = updatedData[index];

            // Parcourir chaque niveau de la hiérarchie du champ
            for (const part of fieldParts) {
                if (!nestedObject[part]) {
                    // Si le niveau du champ n'existe pas, le créer en tant qu'objet vide
                    nestedObject[part] = {};
                }
                nestedObject = nestedObject[part];
            }

            // Mettre à jour le champ final avec sa valeur
            nestedObject[nestedField] = value;

            return updatedData;
        });
    };


    const saveDistractionsInterruptionsData = async () => {
        try {
            if (user) {
                const userId = firebase.auth().currentUser.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);
                await docRef.update({ DistractionsInterruptions_6: distractionsInterruptionsData });
            }
        } catch (error) {
            console.log("Error saving data:", error);
            // Gérer les erreurs de sauvegarde des données
        }
    };

    const createNewMap = async () => {
        const updatedData = [...distractionsInterruptionsData];
        updatedData.push({});
        setDistractionsInterruptionsData(updatedData);
    };

    const handleDelete = async (index) => {
        const updatedData = [...distractionsInterruptionsData];
        updatedData.splice(index, 1);
        setDistractionsInterruptionsData(updatedData);
    };

    useEffect(() => {
        if (distractionsInterruptionsData.length > 0) {
            const timeoutId = setTimeout(() => {
                saveDistractionsInterruptionsData();
            }, 1000); // Définissez ici le délai de temporisation souhaité (en millisecondes)

            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [distractionsInterruptionsData]);

    return (
        <div className="tab-pane containerDashboardContent container-fluid" role="tabpanel" id="tab-6">
            <h5 className={"mt-3"}>
                <span className="introText">
                    "One way to boost our willpower and focus is to manage our distractions instead of letting them manage us." - Daniel Goleman
                </span>
            </h5>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.5em" }}>DISTRACTIONS</span> <br/>
                                <span className={"subtitleMaj"}>AT WORK (EXTERNAL & INTERNAL)</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.5em" }}>DISTRACTIONS</span> <br/>
                                <span className={"subtitleMaj"}>AT HOME (EXTERNAL & INTERNAL)</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.5em" }}>DISTRACTIONS</span> <br/>
                                <span className={"subtitleMaj"}>ELSEWHERE (EXTERNAL & INTERNAL)</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.5em" }}>INTERRUPTIONS</span> <br/>
                                <span className={"subtitleMaj"}>AT WORK</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.5em" }}>INTERRUPTIONS</span> <br/>
                                <span className={"subtitleMaj"}>AT HOME</span>
                            </th>
                            <th>
                                <span style={{ textDecoration: "underline", fontSize: "1.5em" }}>INTERRUPTIONS</span> <br/>
                                <span className={"subtitleMaj"}>ELSEWHERE </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {distractionsInterruptionsData.map((item, index) => (
                            <tr key={index}>
                                <td><DistractionsInterruptionsItem content={item.distractions_work} field={"distractions_work"} index={index} saveDistractionsInterruptionsData={saveDistractionsInterruptionsData} updateDistractionsInterruptionsData={updateDistractionsInterruptionsData}/></td>
                                <td><DistractionsInterruptionsItem content={item.distractions_home} field={"distractions_home"} index={index} saveDistractionsInterruptionsData={saveDistractionsInterruptionsData} updateDistractionsInterruptionsData={updateDistractionsInterruptionsData}/></td>
                                <td><DistractionsInterruptionsItem content={item.distractions_else} field={"distractions_else"} index={index} saveDistractionsInterruptionsData={saveDistractionsInterruptionsData} updateDistractionsInterruptionsData={updateDistractionsInterruptionsData}/></td>
                                <td><DistractionsInterruptionsItem content={item.interruptions_work} field={"interruptions_work"} index={index} saveDistractionsInterruptionsData={saveDistractionsInterruptionsData} updateDistractionsInterruptionsData={updateDistractionsInterruptionsData}/></td>
                                <td><DistractionsInterruptionsItem content={item.interruptions_home} field={"interruptions_home"} index={index} saveDistractionsInterruptionsData={saveDistractionsInterruptionsData} updateDistractionsInterruptionsData={updateDistractionsInterruptionsData}/></td>
                                <td><DistractionsInterruptionsItem content={item.interruptions_else} field={"interruptions_else"} index={index} saveDistractionsInterruptionsData={saveDistractionsInterruptionsData} updateDistractionsInterruptionsData={updateDistractionsInterruptionsData}/></td>
                                <TiDelete style={{ fontSize: "2.3em", cursor:"pointer" }} className={"text-danger mt-4"} onClick={() => handleDelete(index)}/>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="w-100 d-flex justify-content-center">
                <TiPlus style={{ fontSize: "1.8em", cursor:"pointer" }} className={"text-primary"} onClick={createNewMap}/>
            </div>
        </div>
    );
};

export default DistractionsInterruptions;

const DistractionsInterruptionsItem = ({ content, field, index, updateDistractionsInterruptionsData }) => {

    const handleSolutionChange = (value) => {
        updateDistractionsInterruptionsData(index, `${field}.solution`, value);
    };

    const handleSourceChange = (value) => {
        updateDistractionsInterruptionsData(index, `${field}.source`, value);
    };
    return (
        <div>
            <label className={"fw-bolder"}>Source</label>
            <ReactQuill
                value={content?.source ?? ''}
                onChange={handleSourceChange}
                modules={{ toolbar: []}}
            />
            <label className={"fw-bolder"}>Solution</label>
            <ReactQuill
                value={content?.solution ?? ''}
                onChange={handleSolutionChange}
                modules={{ toolbar: []}}
            />
        </div>
    );
};