import React, { useEffect, useState } from 'react';
import CustomModal from "./CustomModal";
import firebase from "../../firebaseConfig";
import "../../styles/generalTabs.css"
import {TiPencil} from "react-icons/ti";

const ReleaseRecovery = ({ user }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [displayContent, setDisplayContent] = useState("");
    const [fieldName, setFieldName] = useState("");
    const [releaseRecovery, setReleaseRecovery] = useState({
        release_1: '',
        release_2: '',
        recoveryEnd_3: '',
        restOff_4: '',
        restVacation_5: '',
    });

    useEffect(() => {
        const fetchInputValue = async () => {
            try {
                if (user) {
                    const userId = firebase.auth().currentUser.uid;
                    const docRef = firebase.firestore().collection('users').doc(userId);
                    const docSnapshot = await docRef.get();
                    if (docSnapshot.exists) {
                        const data = docSnapshot.data().Release_5;
                        setReleaseRecovery(data);
                    }
                }
            } catch (error) {
                console.log(error);
                // Gérer les erreurs de récupération des données
            }
        };
        fetchInputValue();
    }, [user]);

    const updateInputValue = async (newValue) => {
        try {
            if (user) {
                const userId = user.uid;
                const docRef = firebase.firestore().collection('users').doc(userId);

                // Créer un objet avec le champ spécifié et sa nouvelle valeur
                const updatedData = {
                    ["Release_5." + fieldName]: newValue
                };

                // Mettre à jour l'état dynamiquement
                setReleaseRecovery(prevState => ({
                    ...prevState,
                    [fieldName]: newValue
                }));

                if (modalIsOpen) setModalIsOpen(false);
                await docRef.update(updatedData);
            }
        } catch (error) {
            console.log(error);
            // Gérer les erreurs de mise à jour des données
        }
    };

    const openModal = (fieldName, title) => {
        setDisplayContent(releaseRecovery[fieldName]);
        setFieldName(fieldName);
        setModalTitle(title);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="tab-pane containerDashboardContent container-fluid" role="tabpanel" id="tab-5">
            <h5 className={"mt-3"}>
                <span className="introText">
                    "In this game, everyone needs a break to refuel, and jump back in full throttle." - Helen Edwards
                </span>
            </h5>
            <div className="container">
                <div className="col">
                    <div className="row">
                        <Card openModal={openModal} title={"RELEASE STAGE"} subTitle={"WHAT ARE YOUR LOW GRADE ACTIVITIES?"} fieldName={"release_1"} releaseRecovery={releaseRecovery} />
                        <CardRecoveryInter openModal={openModal} subTitle={"WHAT ARE YOUR BEST/FAVORITE WAYS OF RECHARGING BETWEEN TASKS?"} fieldName={"release_2"} releaseRecovery={releaseRecovery} />
                        <Card openModal={openModal} title={"FULL REST (DAYS OFF)"} subTitle={"HOW DO YOU FULLY RECHARGE DURING YOUR DAYS OFF?"} fieldName={"restOff_4"} releaseRecovery={releaseRecovery} />
                        <div className="col-xl-4 col-xs-12 col-sm-6 mt-3"></div>
                        <Card openModal={openModal} title={"RECOVERY (END OF DAY)"} subTitle={"HOW DO YOU FULLY RECHARGE FOR ANOTHER WORK DAY?"} fieldName={"recoveryEnd_3"} releaseRecovery={releaseRecovery} />
                        <Card openModal={openModal} title={"FULL REST (VACATIONS)"} subTitle={"HOW DO YOU FULLY RECHARGE ON VACATION?"} fieldName={"restVacation_5"} releaseRecovery={releaseRecovery} />
                    </div>
                </div>
            </div>

            {modalIsOpen && (
                <CustomModal
                    title={modalTitle}
                    closeModal={closeModal}
                    content={displayContent}
                    isOpen={modalIsOpen}
                    saveField={updateInputValue}
                />
            )}
        </div>
    );
};

const Card = ({ openModal, title, subTitle, fieldName, releaseRecovery }) => {
    return (
        <div className="col-xl-4 col-xs-12 col-sm-6 mt-3" style={{ cursor: "pointer" }}>
            <div className="card">
                <div className="card-header text-center d-xxl-flex justify-content-xxl-center align-items-xxl-center" style={{ minHeight: "5em" }}>
                    <div onClick={() => openModal(fieldName, title)}>
                        <span style={{ textDecoration: "underline", cursor: "pointer", fontSize: "1.35em", fontWeight: "bold" }}>{title}</span>
                        <TiPencil style={{ marginLeft: "0.5em", position: "absolute", top:"1.1em" }} />
                        {subTitle ? (
                            <p className="subtitleMaj d-xxl-flex justify-content-xxl-center align-items-xxl-center">{subTitle}</p>
                        ) : null}
                    </div>
                </div>
                <div style={{ overflow: "hidden" }}>
                    <div onDoubleClick={() => openModal(fieldName, title)} className="contentText p-3">{releaseRecovery[fieldName]}</div>
                </div>
            </div>
        </div>
    );
};

const CardRecoveryInter = ({ openModal, subTitle, fieldName, releaseRecovery }) => {
    return (
        <div className="col-xl-4 col-xs-12 col-sm-6 mt-3" style={{ cursor: "pointer" }}>
            <div className="card">
                <div className="card-header text-center d-xxl-flex justify-content-xxl-center align-items-xxl-center" style={{ minHeight: "5em" }}>
                    <div onClick={() => openModal(fieldName, "RECOVERY (INTER FLOW BLOCKS)")}>
                        <span style={{ textDecoration: "underline", cursor: "pointer", fontSize: "1.3em", fontWeight: "bold" }}>RECOVERY (INTER FLOW BLOCKS)</span>
                        <TiPencil style={{ marginLeft: "0.5em", position: "absolute", top:".9em"}} />
                        {subTitle ? (
                            <p className="subtitleMaj d-flex justify-content-center align-items-center">{subTitle}</p>
                        ) : null}
                    </div>
                </div>
                <div style={{ overflow: "hidden" }}>
                    <div onDoubleClick={() => openModal(fieldName, "RECOVERY (INTER FLOW BLOCKS)")} className="contentText p-3">{releaseRecovery[fieldName]}</div>
                </div>
            </div>
        </div>
    );
};

export default ReleaseRecovery;
